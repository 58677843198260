import React from 'react'
import style from './news.module.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { landData } from '../../Helpers/Lang';
import dummyImage from '../../assets/img/home/news/dummy.png'
import { useNavigate } from 'react-router-dom';
import { PlayIcon } from '../../assets/icons';

const News = ({ route }) => {

    const currentLang = localStorage.getItem('lang')
    const navigation = useNavigate();

    const dataNews = {
        title: landData[currentLang].home.news.title,
        data: [
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[0].title,
                date: landData[currentLang].home.news.videos[0].date,
                path: '1'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[1].title,
                date: landData[currentLang].home.news.videos[1].date,
                path: '2'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[2].title,
                date: landData[currentLang].home.news.videos[2].date,
                path: '3'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[3].title,
                date: landData[currentLang].home.news.videos[3].date,
                path: '4'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[4].title,
                date: landData[currentLang].home.news.videos[4].date,
                path: '5'
            }
        ]
    }

    const handleClick = (item) => {

        navigation(`/news/${item.path}`)

    }

    return (
        <>
            <PageWrapper
                title={Metadata[currentLang].home.title}
                desc={Metadata[currentLang].home.desc}
                whiteHeader
                path={route}
            >
                <div className={style.newsWrapper}>
                    <Breadcrumbs path={route} />
                    <div className={style.containNews}>

                        {
                            dataNews.data.map((item, idx) => {
                                return (
                                    <button
                                        onClick={(e) => handleClick(item)}
                                        key={idx}
                                        className={style.subBigNews} >
                                        <div className={style.newsOverlay}>
                                            <PlayIcon color={'white'} width={62} height={62}/>
                                        </div>
                                        <img src={item.img} alt="sub_news_image" />
                                        <h6>{item.title}</h6>
                                        <span>{item.date}</span>
                                    </button>

                                )
                            })
                        }
                    </div>
                </div>
            </PageWrapper >
        </>
    )
}

export default News