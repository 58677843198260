import React from 'react'
import style from './detailedinfo.module.scss'
import { landData } from '../../../Helpers/Lang'
import { Line, Circle } from 'rc-progress';
import useWindowDimensions from '../../../Helpers/windowWidth';
import { useNavigate } from 'react-router-dom';

const DetailedInfo = () => {

    const currentLang = localStorage.getItem('lang')

    const navigation = useNavigate();

    const { width } = useWindowDimensions();

    const detailedData = [
        {
            name: landData[currentLang].home.detailedInfo.progress[0].name,
            percent: landData[currentLang].home.detailedInfo.progress[0].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[1].name,
            percent: landData[currentLang].home.detailedInfo.progress[1].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[2].name,
            percent: landData[currentLang].home.detailedInfo.progress[2].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[3].name,
            percent: landData[currentLang].home.detailedInfo.progress[3].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[4].name,
            percent: landData[currentLang].home.detailedInfo.progress[4].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[5].name,
            percent: landData[currentLang].home.detailedInfo.progress[5].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[6].name,
            percent: landData[currentLang].home.detailedInfo.progress[6].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[7].name,
            percent: landData[currentLang].home.detailedInfo.progress[7].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[8].name,
            percent: landData[currentLang].home.detailedInfo.progress[8].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[9].name,
            percent: landData[currentLang].home.detailedInfo.progress[9].percent
        },
        {
            name: landData[currentLang].home.detailedInfo.progress[10].name,
            percent: landData[currentLang].home.detailedInfo.progress[10].percent
        }
    ]

    return (
        <div className={style.detailedInfoWrapper}>
            <div className={style.infoWrapperInner}>
                <div className={style.infocardWrapper}>
                    <h4>{landData[currentLang].home.detailedInfo.info.title}</h4>
                    <span>{landData[currentLang].home.detailedInfo.info.desc}</span>
                    <button onClick={() => navigation('/yapim')}>{landData[currentLang].home.detailedInfo.info.buttonText}</button>
                </div>
                <div className={style.progressCircular}>
                    <Circle percent={76} strokeWidth={5} trailWidth={5} strokeColor="#da2125" />
                    <span>{landData[currentLang].home.detailedInfo.pieTitle.value}</span>
                    <h5>{landData[currentLang].home.detailedInfo.pieTitle.title}</h5>
                </div>
            </div>
            <div className={style.progressLine}>
                {
                    detailedData.map((item) => {
                        return (
                            <div className={style.progressLineInner}>
                                <span>{item.name}</span>
                                <div className={style.pLine}>
                                    <h6 style={{ left: width > 970 ? item.percent / 2 : width / 2 }}>{item.percent + "%"}</h6>
                                    <Line strokeLinecap={'square'} percent={item.percent} strokeWidth={4} trailWidth={4} strokeColor="#da2125" />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div >
    )
}

export default DetailedInfo