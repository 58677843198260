import React from 'react'
import style from './newsletter.module.scss';
import { useNavigate } from 'react-router-dom';
import { landData } from '../../../Helpers/Lang';


const Newsletter = () => {

    const currentLang = localStorage.getItem('lang')

    const nav = useNavigate()

    return (
        <div className={style.containerNewsletter}>
            <div className={style.containerInnerNewsletter}>
                <h5>{landData[currentLang].home.newsletter.title}</h5>
                <span>{landData[currentLang].home.newsletter.desc}</span>
            </div>
            <button onClick={() => nav('/onerisikayet')}>{landData[currentLang].home.newsletter.title}</button>
        </div>
    )
}

export default Newsletter