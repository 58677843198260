import React, { useEffect, useState } from 'react'
import style from './breadcrumbs.module.scss';
import { useLocation } from 'react-router-dom';
import { landData } from '../../Helpers/Lang';

const Breadcrumbs = ({ path }) => {

    const location = useLocation();
    const newPath = location.pathname.split('/')[2];
    const currentLang = localStorage.getItem('lang');
    const [pageName, setPageName] = useState();

    useEffect(() => {

        if (path === "/news") {
            setPageName(landData[currentLang].home.news.title);
        } else if (path === "/stations" || path === `/stations/${newPath}`) {
            setPageName(landData[currentLang].navbar.parts[1].innerTitle[0].name);

        } else if (path === "/warehouse") {
            setPageName(landData[currentLang].technicInfos.warehouse.title);
        }
         else if (path === "/gdm") {
            setPageName(landData[currentLang].technicInfos.gdmNumbers.title);
        }
         else if (path === "/tenderinfos") {
            setPageName(landData[currentLang].technicInfos.ihaleInfos.title);
        }
         else if (path === "/aboutus") {
            setPageName(landData[currentLang].aboutus.title);
        }
         else if (path === "/yapim") {
            setPageName(landData[currentLang].toBeCont.mainTitle);
        }
         else if (path === "/onerisikayet") {
            setPageName(landData[currentLang].suggAndComp.mainTitle);
        }
         else if (path === "/assetmedia") {
            setPageName(landData[currentLang].images.mainTitle);
        }
         else if (path === "/reports") {
            // setPageName(landData[currentLang].images.mainTitle);
        }

    }, [])

    return (
        <div className={style.breadcrumbs}>
            <div className={style.breadcrumbsInner}>
                <a href="/*">{landData[currentLang].navbar.parts[0].title}</a>
                <span>{" >> "}</span>
                <a href={`${path}`}>{pageName}</a>
            </div>
        </div>
    )
}

export default Breadcrumbs