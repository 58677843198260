import React from 'react'
import style from './contact.module.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import { useLocation } from 'react-router-dom';
import { MailIcon, MapIcon, NavigateArrow, PhoneIcon } from '../../assets/icons';
import { landData } from '../../Helpers/Lang';

const Contact = ({ route }) => {

    const currentLang = localStorage.getItem('lang')


    return (
        <>
            <PageWrapper
                title={Metadata[currentLang].home.title}
                desc={Metadata[currentLang].home.desc}
                whiteHeader
                path={route}
            >
                <div className={style.contactMainWrapper}>
                    <div className={style.mapContainer}>
                        <div className={style.mapInnerContainer}>
                            <h3>{landData[currentLang].contact.infos.title}</h3>
                            <div className={style.columnContact1}>
                                <MapIcon color={'#da2125'} />
                                <span>{landData[currentLang].contact.infos.location}</span>
                            </div>
                            <div className={style.columnContact1}>
                                <MailIcon color={'#da2125'} />
                                <a href='#'>{landData[currentLang].contact.infos.mail}</a>
                            </div>
                            <div className={style.columnContact1}>
                                <PhoneIcon color={'#da2125'} />
                                <span>{landData[currentLang].contact.infos.phone}</span>
                            </div>
                            <iframe
                                src="https://yandex.com.tr/map-widget/v1/?ll=29.116838%2C41.020340&mode=poi&poi%5Bpoint%5D=29.097065%2C41.025870&poi%5Buri%5D=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoyMjE2MDQ1MzMzEh5Uw7xya2l5ZSwgxLBzdGFuYnVsLCDDnG1yYW5peWUiCg3PxuhBFX0aJEI%2C&utm_campaign=morda&utm_medium=com&utm_source=yandex&z=13.1"
                                width="100%"
                                height="100%"
                                frameborder="1"
                                allowfullscreen="true">
                            </iframe>
                        </div>
                        {/* <iframe
                            src="https://yandex.com.tr/map-widget/v1/?ll=28.922201%2C41.069710&mode=search&oid=84446855965&ol=biz&z=15.94"
                            frameborder="1"
                            allowfullscreen="true"
                        /> */}
                        <div className={style.innerForm}>
                            <p>{landData[currentLang].contact.form.title}</p>
                            <form action="#">
                                <label>{landData[currentLang].contact.form.name}</label>
                                <input type="text" id="fname" name="firstname" placeholder={landData[currentLang].contact.form.name} />

                                <label>{landData[currentLang].contact.form.surname}</label>
                                <input type="text" id="lname" name="lastname" placeholder={landData[currentLang].contact.form.surname} />

                                <label>{landData[currentLang].contact.form.emailaddress}</label>
                                <input type="email" id="email" name="email" placeholder={landData[currentLang].contact.form.emailaddress} />

                                <label>{landData[currentLang].contact.form.subject}</label>
                                <textarea id="subject" name="subject" placeholder={landData[currentLang].contact.form.subject}></textarea>
                                <input type="submit" value={landData[currentLang].contact.form.buttonText} />
                            </form>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </>
    )
}

export default Contact