export const Metadata = {

    tr: {
        home: {
            title: 'Güryapı - Güvenli Yapı',
            desc: 'Güryapı ile altyapılarınız bize emanet.'
        }

    },
    en: {

        home: {
            title: 'Güryapı - Trustable Construction',
            desc: 'With Güryapı, your infrastructures are entrusted to us.'
        }

    }

}