import React from 'react'
import style from './gdminnumbers.module.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import { landData } from '../../Helpers/Lang';
import Part1Image1 from '../../assets/img/gdm/anahat/first.png';
import Part1Image2 from '../../assets/img/gdm/anahat/third.png';
import Part1Image3 from '../../assets/img/gdm/anahat/fourth.png';
import Part1Image4 from '../../assets/img/gdm/anahat/fifth.png';
import Part1Image5 from '../../assets/img/gdm/anahat/sixth.png';
import Part1Image6 from '../../assets/img/gdm/anahat/seventh.png';
import Part1Image7 from '../../assets/img/gdm/anahat/eight.png';

import Part2Image1 from '../../assets/img/gdm/depo/depo_first.png';
import Part2Image2 from '../../assets/img/gdm/depo/depo_second.png';
import Part2Image3 from '../../assets/img/gdm/depo/depo_third.png';


const SpecCard = ({ item, imageSrc }) => {


    return (
        <div className={style.specCardGdm}>
            <img src={imageSrc} alt="" />
            <span>{item}</span>
        </div>
    );
}


const Gdminnumbers = ({ route }) => {
    const location = useLocation();
    const currentLang = localStorage.getItem('lang');

    const dataGdm = {
        part1: {
            title: landData[currentLang].technicInfos.gdmNumbers.part1.title,
            content: landData[currentLang].technicInfos.gdmNumbers.part1.content,
            images: [Part1Image1, Part1Image1, Part1Image2, Part1Image3, Part1Image4, Part1Image5, Part1Image6, Part1Image7],
        },
        part2: {
            title: landData[currentLang].technicInfos.gdmNumbers.part2.title,
            content: landData[currentLang].technicInfos.gdmNumbers.part2.content,
            images: [Part2Image1, Part2Image2, Part2Image3],
        }
    }

    return (
        <>
            <PageWrapper
                title={Metadata[currentLang].home.title}
                desc={Metadata[currentLang].home.desc}
                whiteHeader
                path={route}
            >
                <div className={style.gdmWrapper}>
                    <Breadcrumbs path={route} />
                    <div className={style.gdmInnerWrapper}>
                        <h3>{landData[currentLang].technicInfos.gdmNumbers.title}</h3>
                        <div className={style.mainLineWrapper}>
                            <h6>{dataGdm.part1.title}</h6>
                            <div className={style.mainLineInnerWrapper}>
                                {
                                    dataGdm.part1.content.map((item, index) => {
                                        return (
                                            <SpecCard item={item} key={index} imageSrc={dataGdm.part1.images[index]} />
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className={style.depotAreaWrapper}>
                            <h6>{dataGdm.part2.title}</h6>
                            <div className={style.mainLineInnerWrapper}>
                                {dataGdm.part2.content.map((content, index) => {
                                    return (
                                        <SpecCard key={index} item={content} imageSrc={dataGdm.part2.images[index]} />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </>
    )
}

export default Gdminnumbers