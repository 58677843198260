const FacebookIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_101)">
        <path
          d="M25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 18.739 4.57104 23.9104 10.5469 24.8481V16.1133H7.37305V12.5H10.5469V9.74609C10.5469 6.61328 12.4131 4.88281 15.2683 4.88281C16.6355 4.88281 18.0664 5.12695 18.0664 5.12695V8.20312H16.4902C14.9375 8.20312 14.4531 9.16675 14.4531 10.1562V12.5H17.9199L17.3657 16.1133H14.4531V24.8481C20.429 23.9104 25 18.739 25 12.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_101">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TwitterIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_102)">
        <path
          d="M7.86475 22.656C17.2964 22.656 22.4565 14.8401 22.4565 8.06422C22.4565 7.8445 22.4517 7.61989 22.4419 7.40016C23.4457 6.67423 24.312 5.77505 25 4.74489C24.0651 5.16083 23.0725 5.43248 22.0562 5.55055C23.1263 4.90907 23.9276 3.90132 24.3115 2.71413C23.3047 3.31078 22.2037 3.73167 21.0557 3.95876C20.2821 3.13683 19.2594 2.59262 18.1455 2.41026C17.0316 2.2279 15.8887 2.41755 14.8935 2.94989C13.8982 3.48223 13.106 4.3276 12.6394 5.35532C12.1728 6.38303 12.0577 7.53584 12.312 8.63551C10.2734 8.53321 8.2791 8.00364 6.45831 7.08114C4.63753 6.15863 3.03093 4.86378 1.74268 3.28053C1.08792 4.40942 0.887559 5.74525 1.18232 7.01655C1.47709 8.28785 2.24486 9.39921 3.32959 10.1248C2.51524 10.0989 1.71872 9.87966 1.00586 9.48512V9.5486C1.00513 10.7333 1.41469 11.8816 2.16491 12.7985C2.91513 13.7154 3.95971 14.3441 5.12109 14.5779C4.36672 14.7843 3.57498 14.8144 2.80713 14.6658C3.13485 15.6846 3.77247 16.5757 4.63101 17.2147C5.48955 17.8537 6.52617 18.2087 7.59619 18.2302C5.77961 19.6572 3.5356 20.4312 1.22559 20.4275C0.815924 20.4269 0.406666 20.4018 0 20.3523C2.34673 21.8579 5.07659 22.6575 7.86475 22.656Z"
          fill={color}
        />
      </g>
      <defs>
        <clippath id="clip0_1_102">
          <rect width="25" height="25" fill="white" />
        </clippath>
      </defs>
    </svg>
  );
};
const MailIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <path
        fill={color}
        d="M23.445 6.435v-.522c0-.99-.811-1.801-1.802-1.801l-.001.001.001-.001H3.261l.007.006-.007-.006c-.99 0-1.801.81-1.801 1.802v12.304c0 .991.81 1.802 1.801 1.802h17.686c1.89-.022 2.457-.49 2.5-1.8l-.002-11.785ZM20.3 4.553l-7.878 5.803-7.827-5.803h15.705ZM4.348 9.062l7.298 5.026.007.005-7.31 5.083.005-10.114Zm.189 10.517 7.502-5.217.131.092a.441.441 0 0 0 .252.079c.088 0 .387-.172.387-.172l7.504 5.218H4.537Zm15.965-.406-7.305-5.08 4.074-2.806 3.23-2.225v10.11Z"
      />
    </svg>
  );
};
const ArrowDownIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <path
        fill={color}
        d="M12 16.8c-.7 0-1.4-.27-1.93-.8L3.55 9.48a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l6.52 6.52c.48.48 1.26.48 1.74 0l6.52-6.52c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L13.93 16c-.53.53-1.23.8-1.93.8Z"
      />
    </svg>
  );
};
const ArrowRightIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width='24'
      height='24'
      fill="none"
    >
      <path
        fill={color}
        d="M8.91 20.67c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l6.52-6.52c.48-.48.48-1.26 0-1.74L8.38 4.61a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l6.52 6.52c.51.51.8 1.2.8 1.93s-.28 1.42-.8 1.93l-6.52 6.52c-.15.14-.34.22-.53.22Z"
      />
    </svg>
  );
};

const CloseIcon = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4141 15.9995L25.707 7.70663C25.8944 7.51906 25.9996 7.26477 25.9995 6.99967C25.9994 6.73456 25.894 6.48034 25.7066 6.29289C25.5191 6.10543 25.2649 6.00008 24.9998 6C24.7347 5.99992 24.4804 6.10511 24.2928 6.29244L15.9999 14.5854L7.70703 6.29244C7.51945 6.10511 7.26517 5.99992 7.00006 6C6.73496 6.00008 6.48074 6.10543 6.29329 6.29289C6.10583 6.48034 6.00048 6.73456 6.0004 6.99967C6.00032 7.26477 6.1055 7.51906 6.29284 7.70663L14.5858 15.9995L6.29284 24.2924C6.19989 24.3853 6.12614 24.4955 6.07581 24.6169C6.02548 24.7382 5.99955 24.8683 5.99951 24.9997C5.99947 25.131 6.02532 25.2611 6.07557 25.3825C6.12583 25.5039 6.1995 25.6142 6.2924 25.7071C6.38529 25.8 6.49558 25.8736 6.61696 25.9239C6.73834 25.9742 6.86843 26 6.99981 26C7.13118 25.9999 7.26125 25.974 7.3826 25.9237C7.50395 25.8733 7.61419 25.7996 7.70703 25.7066L15.9999 17.4137L24.2928 25.7066C24.4804 25.894 24.7347 25.9992 24.9998 25.9991C25.2649 25.999 25.5191 25.8936 25.7066 25.7062C25.894 25.5187 25.9994 25.2645 25.9995 24.9994C25.9996 24.7343 25.8944 24.48 25.707 24.2924L17.4141 15.9995Z"
        fill={color}
      />
    </svg>
  );
};

const PlayIcon = ({ color, width, height }) => {

  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill={"#FFFFFF"} xmlns="http://www.w3.org/2000/svg">
      <path d="M14.25 23.376V12.624C14.2502 12.4895 14.2866 12.3575 14.3554 12.2419C14.4242 12.1262 14.5228 12.0312 14.6409 11.9668C14.759 11.9024 14.8922 11.8709 15.0267 11.8757C15.1611 11.8805 15.2918 11.9214 15.405 11.994L23.769 17.3685C23.8749 17.4364 23.962 17.5298 24.0224 17.6401C24.0828 17.7505 24.1144 17.8742 24.1144 18C24.1144 18.1258 24.0828 18.2495 24.0224 18.3599C23.962 18.4702 23.8749 18.5636 23.769 18.6315L15.405 24.0075C15.2918 24.0801 15.1611 24.121 15.0267 24.1258C14.8922 24.1306 14.759 24.0991 14.6409 24.0347C14.5228 23.9703 14.4242 23.8753 14.3554 23.7596C14.2866 23.644 14.2502 23.512 14.25 23.3775V23.376Z" fill={color} />
      <path d="M1.5 18C1.5 8.8875 8.8875 1.5 18 1.5C27.1125 1.5 34.5 8.8875 34.5 18C34.5 27.1125 27.1125 34.5 18 34.5C8.8875 34.5 1.5 27.1125 1.5 18ZM18 3.75C14.2207 3.75 10.5961 5.25133 7.92373 7.92373C5.25133 10.5961 3.75 14.2207 3.75 18C3.75 21.7793 5.25133 25.4039 7.92373 28.0763C10.5961 30.7487 14.2207 32.25 18 32.25C21.7793 32.25 25.4039 30.7487 28.0763 28.0763C30.7487 25.4039 32.25 21.7793 32.25 18C32.25 14.2207 30.7487 10.5961 28.0763 7.92373C25.4039 5.25133 21.7793 3.75 18 3.75Z" fill={color} />
    </svg>
  );
}

const HamburgerMenu = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 5H6C5.44772 5 5 5.44772 5 6V14C5 14.5523 5.44772 15 6 15H14C14.5523 15 15 14.5523 15 14V6C15 5.44772 14.5523 5 14 5Z"
        fill={color}
      />
      <path
        d="M26 5H18C17.4477 5 17 5.44772 17 6V14C17 14.5523 17.4477 15 18 15H26C26.5523 15 27 14.5523 27 14V6C27 5.44772 26.5523 5 26 5Z"
        fill={color}
      />
      <path
        d="M14 17H6C5.44772 17 5 17.4477 5 18V26C5 26.5523 5.44772 27 6 27H14C14.5523 27 15 26.5523 15 26V18C15 17.4477 14.5523 17 14 17Z"
        fill={color}
      />
      <path
        d="M26 17H18C17.4477 17 17 17.4477 17 18V26C17 26.5523 17.4477 27 18 27H26C26.5523 27 27 26.5523 27 26V18C27 17.4477 26.5523 17 26 17Z"
        fill={color}
      />
    </svg>
  );
};
const MapIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <path
        fill={color}
        d="M12 14.17c-2.13 0-3.87-1.73-3.87-3.87S9.87 6.44 12 6.44s3.87 1.73 3.87 3.87-1.74 3.86-3.87 3.86Zm0-6.23c-1.3 0-2.37 1.06-2.37 2.37s1.06 2.37 2.37 2.37 2.37-1.06 2.37-2.37S13.3 7.94 12 7.94Z"
      />
      <path
        fill={color}
        d="M12 22.76a5.97 5.97 0 0 1-4.13-1.67c-2.95-2.84-6.21-7.37-4.98-12.76C4 3.44 8.27 1.25 12 1.25h.01c3.73 0 8 2.19 9.11 7.09 1.22 5.39-2.04 9.91-4.99 12.75A5.97 5.97 0 0 1 12 22.76Zm0-20.01c-2.91 0-6.65 1.55-7.64 5.91C3.28 13.37 6.24 17.43 8.92 20a4.426 4.426 0 0 0 6.17 0c2.67-2.57 5.63-6.63 4.57-11.34-1-4.36-4.75-5.91-7.66-5.91Z"
      />
    </svg>
  );
};
const PhoneIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7465 19.8033L21.8917 17.2942C21.586 17.1626 21.2523 17.1096 20.9209 17.1401C20.5895 17.1705 20.271 17.2834 19.9944 17.4685L16.864 19.5552C14.9508 18.6247 13.4023 17.083 12.4634 15.174L12.4636 15.1737L14.543 11.9965C14.724 11.7206 14.8338 11.4042 14.8626 11.0756C14.8914 10.7469 14.8383 10.4163 14.708 10.1132L12.1965 4.25342C12.0256 3.85566 11.7304 3.52398 11.3551 3.30803C10.9799 3.09209 10.5448 3.00351 10.115 3.05554C8.42607 3.27269 6.87398 4.09728 5.74865 5.37525C4.62331 6.65323 4.00173 8.29718 4 10C4 19.9253 12.0747 28 22 28C23.7028 27.9983 25.3468 27.3767 26.6248 26.2513C27.9028 25.126 28.7273 23.5738 28.9445 21.8849C28.9964 21.4551 28.9077 21.02 28.6917 20.6448C28.4758 20.2696 28.1442 19.9744 27.7465 19.8033V19.8033Z"
        fill={color}
      />
    </svg>
  );
};




const LanguageIcon = ({ language }) => {
  return language === 'tr' ? (
    <svg
      width="36"
      height="24"
      viewBox="0 0 89 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_674_6525)">
        <path d="M0 0H88.1973V50.3984H0V0Z" fill="#E30A17" />
        <path
          d="M31.2367 37.7988C39.355 37.7988 45.9362 32.1578 45.9362 25.1992C45.9362 18.2406 39.355 12.5996 31.2367 12.5996C23.1183 12.5996 16.5371 18.2406 16.5371 25.1992C16.5371 32.1578 23.1183 37.7988 31.2367 37.7988Z"
          fill="white"
        />
        <path
          d="M34.9115 35.2789C41.4062 35.2789 46.6711 30.7661 46.6711 25.1992C46.6711 19.6323 41.4062 15.1195 34.9115 15.1195C28.4168 15.1195 23.1519 19.6323 23.1519 25.1992C23.1519 30.7661 28.4168 35.2789 34.9115 35.2789Z"
          fill="#E30A17"
        />
        <path
          d="M42.8735 25.1993L56.1693 28.9022L47.952 19.2078V31.1907L56.1693 21.4963L42.8735 25.1993Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_674_6525">
          <rect width="88.1973" height="50.3984" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : language === 'en' ? (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_302_1348)">
        <mask
          id="mask0_302_1348"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="36"
          height="24"
        >
          <rect width="36" height="24" fill="white" />
        </mask>
        <g mask="url(#mask0_302_1348)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0V24H36V0H0Z"
            fill="#2E42A5"
          />
          <mask
            id="mask1_302_1348"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="36"
            height="24"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 0V24H36V0H0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_302_1348)">
            <mask
              id="mask2_302_1348"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="36"
              height="24"
            >
              <rect width="36" height="24" fill="white" />
            </mask>
            <g mask="url(#mask2_302_1348)">
              <path
                d="M-4.00865 22.2854L3.91325 25.2635L36.1796 3.23787L40.3583 -1.18761L31.8869 -2.18297L18.7262 7.3085L8.13324 13.7035L-4.00865 22.2854Z"
                fill="white"
              />
              <path
                d="M-2.92413 24.3719L1.11171 26.1001L38.8577 -1.59884H33.191L-2.92413 24.3719Z"
                fill="#F50100"
              />
              <path
                d="M40.0085 22.2854L32.0866 25.2635L-0.179809 3.23787L-4.35843 -1.18761L4.1129 -2.18297L17.2736 7.3085L27.8666 13.7035L40.0085 22.2854Z"
                fill="white"
              />
              <path
                d="M39.7382 23.7829L35.7023 25.5111L19.6297 13.6518L14.8645 12.3267L-4.76051 -1.17246H0.906281L20.5202 12.0063L25.7302 13.5952L39.7382 23.7829Z"
                fill="#F50100"
              />
              <mask id="path-10-inside-1_302_1348" fill="white">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.2499 -2H13.7499V8H-2.21912V16H13.7499V26H22.2499V16H38.2809V8H22.2499V-2Z"
                />
              </mask>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.2499 -2H13.7499V8H-2.21912V16H13.7499V26H22.2499V16H38.2809V8H22.2499V-2Z"
                fill="#F50100"
              />
              <path
                d="M13.7499 -2V-4H11.7499V-2H13.7499ZM22.2499 -2H24.2499V-4H22.2499V-2ZM13.7499 8V10H15.7499V8H13.7499ZM-2.21912 8V6H-4.21912V8H-2.21912ZM-2.21912 16H-4.21912V18H-2.21912V16ZM13.7499 16H15.7499V14H13.7499V16ZM13.7499 26H11.7499V28H13.7499V26ZM22.2499 26V28H24.2499V26H22.2499ZM22.2499 16V14H20.2499V16H22.2499ZM38.2809 16V18H40.2809V16H38.2809ZM38.2809 8H40.2809V6H38.2809V8ZM22.2499 8H20.2499V10H22.2499V8ZM13.7499 0H22.2499V-4H13.7499V0ZM15.7499 8V-2H11.7499V8H15.7499ZM-2.21912 10H13.7499V6H-2.21912V10ZM-0.219116 16V8H-4.21912V16H-0.219116ZM13.7499 14H-2.21912V18H13.7499V14ZM15.7499 26V16H11.7499V26H15.7499ZM22.2499 24H13.7499V28H22.2499V24ZM20.2499 16V26H24.2499V16H20.2499ZM38.2809 14H22.2499V18H38.2809V14ZM36.2809 8V16H40.2809V8H36.2809ZM22.2499 10H38.2809V6H22.2499V10ZM20.2499 -2V8H24.2499V-2H20.2499Z"
                fill="white"
                mask="url(#path-10-inside-1_302_1348)"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_302_1348">
          <rect width="36" height="24" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : null;
};

export {
  FacebookIcon,
  TwitterIcon,
  LanguageIcon,
  MailIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  CloseIcon,
  HamburgerMenu,
  MapIcon,
  PhoneIcon,
  PlayIcon
};
