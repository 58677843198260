import React from 'react'
import style from './newscomp.module.scss'
import dummyImage from '../../../assets/img/home/news/dummy.png'
import { landData } from '../../../Helpers/Lang'
import { useNavigate } from 'react-router-dom'
import { PlayIcon } from '../../../assets/icons'

const NewsComp = () => {

    const currentLang = localStorage.getItem('lang')
    const navigation = useNavigate();
    const dataNews = {
        title: landData[currentLang].home.news.title,
        data: [
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[0].title,
                date: landData[currentLang].home.news.videos[0].date,
                path: '1'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[1].title,
                date: landData[currentLang].home.news.videos[1].date,
                path: '2'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[2].title,
                date: landData[currentLang].home.news.videos[2].date,
                path: '3'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[3].title,
                date: landData[currentLang].home.news.videos[3].date,
                path: '4'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[4].title,
                date: landData[currentLang].home.news.videos[4].date,
                path: '5'
            }
        ]
    }

    const handleClick = (item) => {
        navigation(`/news/${item.path}`);
    }

    return (
        <div className={style.containerNews}>
            <div className={style.contentTitle}>
                <h4>{dataNews.title}</h4>
            </div>
            <div className={style.containNews}>
                {
                    dataNews.data.map((item, idx) => {
                        if (idx === 0) {
                            return (
                                <button
                                    key={idx}
                                    className={style.subNews}
                                    onClick={() => handleClick(item)}
                                >
                                    <div className={style.newsOverlay}>
                                        <PlayIcon color={'white'} width={62} height={62} />
                                    </div>
                                    <img src={item.img} alt="" />
                                    <h6>{item.title}</h6>
                                    <span>{item.date}</span>
                                </button>
                            );
                        } else if (idx === 1) {
                            return (
                                <button
                                    onClick={() => handleClick(item)}
                                    key={idx}
                                    className={style.subNews}
                                >
                                    <div className={style.newsOverlay}>
                                        <PlayIcon color={'white'} width={62} height={62} />
                                    </div>
                                    <img src={item.img} alt="" />
                                    <h6>{item.title}</h6>
                                    <span>{item.date}</span>
                                </button>
                            );
                        }
                        return (
                            <button
                                onClick={() => handleClick(item)}
                                key={idx}
                                className={style.subBigNews}
                            >
                                <div className={style.newsOverlay}>
                                    <PlayIcon color={'white'} width={62} height={62} />
                                </div>
                                <img src={item.img} alt="" />
                                <h6>{item.title}</h6>
                                <span>{item.date}</span>
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default NewsComp