import React from 'react'
import style from './suggandcomp.module.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { landData } from '../../Helpers/Lang';



const SuggAndComp = ({ route }) => {

    const currentLang = localStorage.getItem('lang');

    return (
        <>
            <PageWrapper
                title={Metadata[currentLang].home.title}
                desc={Metadata[currentLang].home.desc}
                whiteHeader
                path={route}
            >
                <div className={style.suggestionWrapper}>
                    <Breadcrumbs path={route} />
                    <div className={style.suggestionInnerWrapper}>
                        <h3>{landData[currentLang].suggAndComp.mainTitle}</h3>
                        <div className={style.innerForm}>
                            <form action="#">
                                <label>{landData[currentLang].contact.form.name}</label>
                                <input type="text" id="fname" name="firstname" placeholder={landData[currentLang].contact.form.name} />
                                
                                <label>{landData[currentLang].contact.form.surname}</label>
                                <input type="text" id="lname" name="lastname" placeholder={landData[currentLang].contact.form.surname} />

                                <label>{landData[currentLang].contact.form.emailaddress}</label>
                                <input type="email" id="email" name="email" placeholder={landData[currentLang].contact.form.emailaddress} />

                                <label>{landData[currentLang].contact.form.phoneNumber}</label>
                                <input type='tel' id="tel" name="tel" placeholder={landData[currentLang].contact.form.phoneNumber} />

                                <label>{landData[currentLang].contact.form.subject}</label>
                                <textarea id="subject" name="subject" placeholder={landData[currentLang].contact.form.subject}></textarea>
                                <input type="submit" value={landData[currentLang].contact.form.buttonText} />
                            </form>
                        </div>
                        <h5>{landData[currentLang].suggAndComp.content}</h5>
                    </div>
                </div>
            </PageWrapper>
        </>
    )
}

export default SuggAndComp