import React, { useEffect, useState } from 'react'
import style from './pagewrapper.module.scss';
import { Helmet } from 'react-helmet';
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import Navbar from '../Navbar/Navbar';
import { useLocation } from 'react-router-dom';

const PageWrapper = ({
    children,
    overflowHidden,
    whiteHeader,
    topTitle,
    topImage,
    title,
    desc,
    path,
}) => {

    const currentLang = localStorage.getItem('lang')
    const location = useLocation();
    const whichPage = location.pathname;

    return (
        <div
            // style={{
            //     overflowX: overflowHidden ? 'unset' : 'hidden',
            // bunu açarsan scroll okuyamıyoruz }}
            className={style.pageWrapper}
        >
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                {/* <link rel="canonical" href={`https://vialand.com${path}`} /> */}
                <link
                    rel="apple-touch-icon"
                    sizes="57x57"
                    href="/favicon/apple-icon-57x57.png"
                />
                <link rel="manifest" href="/favicon/manifest.json" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
            {topTitle && (
                <div className={style.contactCard}>
                    <img src={topImage} alt={topTitle} />
                    <h1>{topTitle}</h1>
                </div>
            )}
            <Navbar />
            {
                whichPage !== "/contact"
                &&
                (< Header
                    whichPage={whichPage}
                    whiteHeader={whiteHeader}
                />)
            }
            {children}
            <Footer />
        </div>
    )
}

export default PageWrapper