import React, { useEffect, useState } from 'react'
import style from './aboutus.module.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { landData } from '../../Helpers/Lang';

const AboutUs = ({ route }) => {

  const currentLang = localStorage.getItem('lang');

  const dataAbout = {

    title: landData[currentLang].aboutus.title,
    project: {
      title: landData[currentLang].aboutus.project.title,
      content: {
        column1: landData[currentLang].aboutus.project.content.column1,
        column2: landData[currentLang].aboutus.project.content.column2
      },
      subContent: landData[currentLang].aboutus.project.subContent,
      innerContent1: landData[currentLang].aboutus.project.innerContent1,
      innerContent2: landData[currentLang].aboutus.project.innerContent2,
      innerContent3: landData[currentLang].aboutus.project.innerContent3,
      innerContent4: landData[currentLang].aboutus.project.innerContent4,
      outroContent: landData[currentLang].aboutus.project.outroContent
    }

  }

  return (
    <>
      <PageWrapper
        title={Metadata[currentLang].home.title}
        desc={Metadata[currentLang].home.desc}
        whiteHeader
        path={route}
      >
        <div className={style.aboutusWrapper}>
          <Breadcrumbs path={route} />
          <div className={style.aboutusInnerWrapper}>
            <h3>{dataAbout.title}</h3>
            <table class="table">
              <thead>
                <tr>
                  <th>{dataAbout.project.title}</th>
                </tr>
              </thead>
              <tbody>
                {
                  dataAbout.project.content.column1.map((item, index) =>
                  (
                    <tr key={index}>
                      <th scope="row">{item}</th>
                      <td>{dataAbout.project.content.column2[index]}</td>
                    </tr>
                  )
                  )
                }
              </tbody>
            </table>
            <h5>{dataAbout.project.subContent}</h5>
            <h6>{dataAbout.project.innerContent1}</h6>
            <h6>{dataAbout.project.innerContent2}</h6>
            <h6>{dataAbout.project.innerContent3}</h6>
            <h6>{dataAbout.project.innerContent4}</h6>
            <span>{dataAbout.project.outroContent}</span>
          </div>
        </div>
      </PageWrapper>
    </>
  )
}

export default AboutUs