export const landData = {

    tr: {
        navbar: {
            parts: [
                {
                    title: "Anasayfa"
                },
                {
                    title: "Teknik Bilgiler",
                    innerTitle: [
                        {
                            name: "İstasyonlar",
                            subTitle: ["Darıca Sahil", "Darıca Cumhuriyet Meydanı", "Farabi Devlet Hastanesi", "Gebze Gar", "Fatih Devlet Hastanesi", "Gebze Kent Meydanı", "Gebze Stadyumu", "Akse Sapağı", "Adliye", "Mutlukent", "OSB"]
                        },
                        {
                            name: "Depo Sahası"
                        },
                        {
                            name: "Sayılarla GDM"
                        },
                        {
                            name: "İhale Bilgileri"
                        }
                    ]
                },
                {
                    title: "Haberler"
                },
                {
                    title: "Görseller",
                    innerTitle: ["Resimler", "Videolar"]

                },
                {
                    title: "İlerleme Raporu"
                },
                {
                    title: "İletişim"
                },

            ]
        },
        aboutus: {
            title: 'Hakkımızda'
        },
        footer: {
            cats: [
                {
                    name: 'Menü',
                    list: ['Hakkımızda', 'Haberler', 'İletişim']
                },
                {
                    name: 'Teknik Bilgiler',
                    list: ['Depo Sahası', 'İstasyonlar']
                },
                {
                    name: 'Görseller',
                    list: ['Resimler', 'Videolar']
                }
            ]
        },
        copyright: '© 2023 Gebze || Tüm hakları saklıdır.',
        slider: {
            mainTitle: 'GEBZE - DARICA METRO HATTI',
            subTitle: 'Hayat Ulaşınca Başlar',
            buttonTitle: 'Genel Bilgiler'
        },
        home: {
            underHeader: {
                firstCard: {
                    title: '90 km/h',
                    desc: 'Tasarım Hızı'
                },
                secondCard: {
                    title: '330.000 Yolcu/Gün',
                    desc: 'Yolcu Kapasitesi'
                },
                thirdCard: {
                    title: '22Dk/Tek Yön',
                    desc: 'Proje Güzergah Seyahat Süresi'
                },
                fourthCard: {
                    title: '17 Araçlık',
                    desc: 'Tren Park Alanı'
                },
                fifthCard: {
                    title: '15,40 km',
                    desc: 'Hat Uzunluğu'
                },
            },
            infoCard: {
                title: 'GEBZE - DARICA METROSU HAKKINDA',
                desc1: 'Darıca Sahil ’den başlayarak Gebze Organize Sanayi Bölgesine ulaşan, 15.40 km ‘lik iki hattan oluşan 11 istasyonlu bir metro projesidir.',
                desc2: 'Gebze Organize Sanayi Bölgesi ve Gebze-Darıca ilçeleri arasındaki karayolu bağlantısına alternatif bir tercih oluşturularak ulaşım ağının geliştirilmesiyle Gebze Marmaray İstasyonuna raylı sistem entegrasyonu amaçlanmıştır.',
                buttonText: 'DETAYLAR'
            },
            detailedInfo: {
                info: {
                    title: 'GEBZE - DARICA METRO YAPIMI DEVAM ETMEKTEDİR...',
                    desc: 'Yapım aşaması 7/24 devam etmekte olup %76 seviyelerinde çalışmalar devam etmektedir.',
                    buttonText: 'DETAYLAR'
                },
                pieTitle: {
                    title: 'Fiziki İlerleme',
                    value: '76%'
                },
                progress: [
                    {
                        name: 'TBM',
                        percent: 100
                    },
                    {
                        name: 'NATM Ana Hat Tünelleri Kazı Destek',
                        percent: 99
                    },
                    {
                        name: 'NATM Ana Hat Tünelleri Kalıcı Kaplama',
                        percent: 82
                    },
                    {
                        name: 'İstasyon Kazı-Destek İşleri',
                        percent: 95
                    },
                    {
                        name: 'İstasyon Kalıcı Betonarme İşleri',
                        percent: 29
                    },
                    {
                        name: 'Depo Sahası Kazı-Dolgu Sanat Yapıları Betonarme Bina İşeri',
                        percent: 93
                    },
                    {
                        name: 'Depo Sahası Ray İşleri',
                        percent: 32
                    },
                    {
                        name: 'Ana Hat Ray İşleri',
                        percent: 16
                    },
                    {
                        name: 'Mimar İşleri',
                        percent: 8
                    },
                    {
                        name: 'Güç Temini',
                        percent: 4
                    },
                    {
                        name: 'Elektromekanik İşler',
                        percent: 13
                    },
                ]
            },
            news: {
                title: 'HABERLER',
                videos: [
                    {
                        title: 'Gebze OSB - Darıca Metro Hattı nın iLk Ray Kaynağı Töreni',
                        date: '16.10.2023'
                    },
                    {
                        title: 'Darıca Sahil İstasyonu TBM Açılış Töreni',
                        date: '14.06.2021'
                    },
                    {
                        title: 'Gebze Gar İstasyonu TBM Çıkış Töreni',
                        date: '12.10.2022'
                    },
                    {
                        title: 'Ulaştırma ve Altyapı Bakanlığı ',
                        date: '24.08.2022'
                    },
                    {
                        title: 'GEBZE - DARICA METRO HATTI 2023 YILINDA HİZMETE GİRECEK',
                        date: '22.08.2022'
                    },
                ]
            },
            newsletter: {
                title: 'Öneri ve Şikayet',
                desc: 'Öneri ve Şikayetleriniz için yanda bulunan butona tıklayarak formu doldurunuz.',
            }
        },
        technicInfos: {
            mainTitle: 'GEBZE DARICA METROSU',
            stations: {
                title: 'İstasyonlar',
                subCats: [
                    {
                        id: 1,
                        title: 'Darıca Sahil',
                        desc: `Aç - Kapa metodu ile inşa edilmekte olan Darıca Sahil İstasyonu,
                         Darıca Sahili’nde Şehit Cevher Dudayev Parkı kuzeyinde konumlandırılmıştır. 
                         İstasyon, sahilin görsel bütünlüğünü bozmamak adına gömülü olarak tasarlanmıştır. 
                         İstasyonun derinliği 10 m, İnşaat Alanı ise 4.436 m²’dir.`
                    },
                    {
                        id: 2,
                        title: 'Darıca Cumhuriyet Meydanı',
                        desc: `Darıca Cumhuriyet Meydanı İstasyonu, İstasyon Caddesi ile Kavala Sokak arasında bulunan mevcut 
                        Cumhuriyet Meydanı Parkı yerine, Yeni Avusturya Tünel Açma Metodu (NATM) ile inşa edilmektedir. 
                        İstasyon konkors yapısı, tünel havalandırma şaftları, merdiven şaftlarının yeryüzünde kalan kısımlarının 
                        görünümleri kent dokusuna uygun şekilde, parka mümkün olduğunca az müdahale edecek şekilde tasarlanmıştır. 
                        İstasyonun derinliği 32 m, İnşaat Alanı ise 5.694 m²’dir.`
                    },
                    {
                        id: 3,
                        title: 'Farabi Devlet Hastanesi',
                        desc: `Farabi Devlet Hastanesi İstasyonunun Aç-Kapa konkors yapısı, Cevahir Sokak ile Karlı Sokak 
                        arasında konumlandırılmıştır. Konkors yapısına bağlanan Yaya Tüneli ile Farabi Devlet Hastanesine çıkışı olacaktır. 
                        Peron Tünelleri, Merdiven Tünelleri ve Yaya Tüneli Yeni Avusturya Tünel Açma Metodu (NATM) ile inşa edilmektedir. 
                        İstasyonun derinliği 37 m, İnşaat Alanı ise 8.167 m²’dir.`
                    },
                    {
                        id: 4,
                        title: 'Gebze Gar',
                        desc: `Gebze Gar İstasyonu, Farabi Devlet Hastanesi ile Fatih Devlet Hastanesi İstasyonları arasında yer almaktadır. 
                        Marmaray ve Hızlı Tren Hatları ile entegrasyonu sağlanacaktır. İstasyon ve istasyona bitişik makas-otopark yapısı, 
                        Ethem Bey Caddesi’nin güneyinde, caddeye dik olarak Gebze Marmaray istasyonu giriş köprüsüne hizalı 
                        şekilde konumlandırılmıştır. İstasyon ve Makas yapısı Aç-Kapa Metodu ile inşa edilmektedir. 
                        İstasyonun derinliği 29 m, İnşaat Alanı ise 26.988 m²’dir.`
                    },
                    {
                        id: 5,
                        title: 'Fatih Devlet Hastanesi',
                        desc: `İstasyon İstanbul Caddesi ve İstasyon Caddesi’nin kesişimindeki kavşakta konumlandırılmıştır. 
                        Yeni Avusturya Tünel Açma Metodu (NATM) ile inşa edilmektedir. İstasyonda 2 adet giriş/çıkış yapısı bulunmaktadır. 
                        İstasyonun derinliği 52 m, İnşaat Alanı ise 4.301 m²’dir.`
                    },
                    {
                        id: 6,
                        title: 'Gebze Kent Meydanı',
                        desc: `Gebze Kent Meydanı İstasyonu, Gebze’nin odak noktası olup, hattın öngörülen yolculuk sayıları 
                        en yüksek olan istasyonlardan biridir. İstasyon, yeni yapılan ve iyi işleyen Gebze Kent Meydanı’na ve 
                        tarihi değeri olan Çoban Mustafa Paşa Camisi’ne müdahale etmemek için, yeni meydanın güneyinde bulunana 
                        eski kent meydanında konumlanmıştır. Böylelikle, eski meydanın yakın çevresinde yeniden canlanması ve 
                        bölgenin tümüyle düzenlenmesi mümkün olmuştur. Yeni Avusturya Tünel Açma Metodu (NATM) ile inşa edilmektedir.
                         Kent Meydanı ve Kuyumcular Çarşısına olmak üzere 2 adet giriş/çıkış yapısı bulunmaktadır. 
                         İstasyonun derinliği 50 m, İnşaat Alanı ise 7.307 m²’dir.`
                    },
                    {
                        id: 7,
                        title: 'Gebze Stadyumu',
                        desc: `Gebze Stadyum İstasyonu, çevresindeki spor kompleksleri, okullar ve belediye başkanlığı binasına 
                        hizmet edecek şekilde konumlandırılmıştır. İstasyon, Şehit Mevlüt Duru ve 952/4 Sokaklar arasında kalan 
                        açık futbol sahası üzerinde konumlandırılmıştır. Aç-kapa sistem bir istasyon olduğundan, havalandırma şaftları 
                        ve diğer yeryüzüne çıkan yapılan, aç-kapı alanı içinde kalmaktadır. İstasyonun derinliği 24 m, İnşaat Alanı ise 6.002 m²’dir.`
                    },
                    {
                        id: 8,
                        title: 'Akse Sapağı',
                        desc: `Akse Sapağı Gebze’de “pivot bölge” konumunda, çok işlek bir alandır. 
                        İstasyon Issıkgöl Caddesi’nin başında, kuzey tarafında yer almaktadır.
                        Gebze Stadyum ile Adliye İstasyonları arasında konumlandırılmıştır. 
                        Yeni Avusturya Tünel Açma Metodu (NATM) ile inşa edilmektedir. 
                        İstasyonun derinliği 29 m, İnşaat Alanı ise 5.802 m²’dir.`
                    },
                    {
                        id: 9,
                        title: 'Adliye',
                        desc: `Adliye İstasyonu, Adliye Sarayı, pazar yeri, okullar ve Gebze Belediyesi’nin yaptığı diğer 
                        yatırımlarla gelişme potansiyeli çok yüksek olan bölgeye hizmet etmek amacıyla konumlandırılırmıştır. 
                        İstasyon ve istasyona bitişik makas-otopark yapısı, Belediyeye ait Fen işleri arazisi üzerinde yer almaktadır. 
                        Yapılması planlanan Gebze – Sabiha Gökçen Havalimanı projesiyle entegrasyon Adliye İstasyonu üzerinden sağlanacaktır.
                         İstasyonun derinliği 26 m, İnşaat Alanı ise 25.169 m²’dir.`
                    },
                    {
                        id: 10,
                        title: 'Mutlukent',
                        desc: `Mutlukent İstasyonu 2415 sokağın batısında paralel olarak aç-kapa konumlandırılmıştır. 
                        4784/2 ve 4778/1 parselleri üzerine oturmaktadır. İstasyon, gelişmekte olan Mutlukent yerleşimine 
                        ve Gebze Olimpik Spor Tesisine hizmet etmek amacıyla bu alanda konumlandırılmıştır. 
                        İstasyonun derinliği 24 m, İnşaat Alanı ise 6.569 m²’dir.`
                    },
                    {
                        id: 11,
                        title: 'OSB',
                        desc: `Yeni Avusturya Tünel Açma Metodu (NATM) ile inşa edilmektedir.
                         OSB servis araçları ve ring otobüs servisleri için park ve durak alanı da planlanmıştır. 
                         İstasyonun derinliği 50 m, İnşaat Alanı ise 6.668 m²’dir.`
                    },
                ],
            },
            warehouse: {
                title: 'Depo Sahası',
                desc: `Depo ve Kontrol Merkezi OSB İstasyonunun yaklaşık 1 km kuzeyindedir. 
                Toplamda 92.984 m² alan üzerinde inşa edilmektedir. 17 adet metro aracı kapasitelidir.`
            },
            gdmNumbers: {
                title: 'Sayılarla GDM',
                part1: {
                    title: 'Ana Hat Özellikleri',
                    content: [`90 km/h Tasarım Hızı`, `80 km/h İşletme Hızı`, `330.000 Yolcu/Gün Yolcu Kapasitesi`, `22 Dk/Tek Yön Proje Güzergah Seyehat Süresi`,
                        `6,83 km TBM Ana Hat Tünel Uzunluğu`, `20,52 km Natm Ana Hat Tünel Uzunluğu`, `6 Adet Tünel Tipi İstasyonu`, `5 Adet Aç-Kapa Tipi İstasyon`]
                },
                part2: {
                    title: 'Depo Sahası',
                    content: [`17 Araçlık Tren Park Alanı`, `45 Adet Makas`, `Toplam Kazı Miktarı 916.664 m3`]
                }
            },
            ihaleInfos: {
                title: 'İhale Bilgileri',
                subTitle: 'Projenin İhale Bilgileri',
                projectNameTitle: 'Projenin Adı',
                constructorTitle: 'Yüklenici',
                consultantTitle: 'Danışman/Müşavir',
                projectName: 'GEBZE DARICA METROSU İNŞAAT VE ELEKTROMEKANİK SİSTEMLER TEMİNİ MONTAJ VE İŞLETMEYE ALMA İŞLERİ',
                constructor: 'EZE İNŞAAT A.Ş',
                consultant: 'TÜMAŞ TÜRK MÜHENDİSLİK MÜŞAVİRLİK VE MÜTEAHHİTLİK A.Ş.'
            }
        },
        news: {
            detail: {
                share: 'Paylaş'
            }
        },
        images: {
            mainTitle: 'Resimler',
            content: [
                {
                    id: 1,
                    title: 'Darıca Sahil Fotoğraf Galerisi'
                },
                {
                    id: 2,
                    title: 'Darıca Cumhuriyet Meydanı Fotoğraf Galerisi'
                },
                {
                    id: 3,
                    title: 'Farabi Devlet Hastanesi Fotoğraf Galerisi'
                },
                {
                    id: 4,
                    title: 'Gebze Gar Fotoğraf Galerisi'
                },
                {
                    id: 5,
                    title: 'Fatih Devlet Hastanesi Fotoğraf Galerisi'
                },
                {
                    id: 6,
                    title: 'Gebze Kent Meydanı Fotoğraf Galerisi'
                },
            ]
        },
        contact: {
            infos: {
                title: 'İletişim Bilgileri',
                location: 'Hakkı Turayliç Cad. No:5 06338 Emek/Çankaya/ANKARA',
                mail: 'asdags@gmail.com',
                phone: '0312 203 10 00'
            },
            form: {
                title: 'Mesaj Gönder',
                name: 'Adınız',
                surname: 'Soyadınız',
                emailaddress: 'Email Adresiniz',
                phoneNumber: 'Telefon Numaranız',
                subject: 'Konu',
                buttonText: 'Gönder'
            }
        },
        aboutus: {
            title: 'Hakkımızda',
            project: {
                title: 'PROJENİN KAPSAMI VE TEKNİK ÖZELLİKLERİ',
                content: {
                    column1: ['Hat Uzunluğu', 'İstasyon Sayısı', 'Hat Yapısı', 'Hat Özelliği', 'Tasarım Hızı (Maksimum)', 'Diğer Projeler İle Bağlantı Noktaları', 'Araç Parklanma Sayısı', 'Araç Ozellikleri', 'Taşıma Kapasitesi (Tasarım)', 'Günlük Yolcu Sayısı', 'Seyahat Süresi'],
                    column2: ['15,4 km', '11 İstasyon- 1 Depo Sahası', '6.782 m TBM, 24.739 m NATM', 'Çift tüp', '90 km / saat', 'Gebze Gar İstasyonu ; Marmaray Aktarması Adliye İstasyonu ; Sabiha Gökçen Metro Entegrasyonu', '1. Aşama (17) – 2. Aşama (15)', 'GO4 Sürücüsüz 4’lü dizi', '1080 yolcu/set/ sefer', '188.982 yolcu/gün (2035)', '40 dakika']
                },
                subContent: 'Metro Hattının tamamlanmasıyla 2022-2047 yılları arasındaki ekonomik kazancı;',
                innerContent1: 'Karayolu Bakım ve İşletme Kazancı = 356,12 Milyon €',
                innerContent2: 'Kaza Kazancı = 7,49 Milyon €',
                innerContent3: 'Dışsal Fayda (Hava Kirliliği, İklim Değişikliği, Gürültü, Doğa ve Yeşil Arazi Maliyeti, Biyolojik Çeşitlilik, Toprak ve Su Kirliliği) = 9,470 Milyon €',
                innerContent4: 'Zaman Kazancı = 2.361,037 Milyon €olmak üzere',
                outroContent: 'Toplamda metro Hattının 25 yıllık vizyonda toplam ekonomik kazancı 2.734,12 Milyon € olacaktır.'
            }
        },
        toBeCont: {
            mainTitle: 'Metro Yapımı Devam Etmektedir...',
            content: {
                title: 'PROJENİN SON DURUMU:',
                subText1: '· Gebze-Darıca Metrosu yapım işi Kocaeli Büyükşehir Belediyesi tarafından 18.05.2018 tarihinde ihale edilmiş ve Yüklenici ile 12.06.2018 tarihinde sözleşmesi imzalanmıştır.',
                subText2: '· Proje kapsamında yaklaşık %2 oranında ilerleme sağlanmış ve Kocaeli Büyükşehir Belediye Başkanlığı söz konusu projenin Bakanlığımızca üstlenilmesini talep etmiştir.',
                subText3: '· Söz konusu projenin Bakanlığımıza devrine ilişkin alınan 17.09.2019 tarih ve 2019/1550 sayılı Cumhurbaşkanı Kararı ile Proje’nin yapımı Bakanlığımız’ca üstlenilmiştir.',
                subText4: '· Projenin Bakanlığımız’a devrine ilişkin Kocaeli Büyükşehir Belediye Başkanlığı ile Bakanlığımız arasında 18.10.2019 tarihinde devir protokolü imzalanmış ve 12.12.2019 tarihinde Yüklenici ile devir sözleşmesi imzalanmıştır. Devir sözleşmesinden sonra günümüze değin kaydedilen toplam fiziki ilerleme %76’dır.',
                subText5: '· 22 Temmuz 2020 tarihinde 28 adet araç alımı ihalesi gerçekleştirilmiş olup, 4 Kasım 2020 tarihinde sözleşmesi imzalanmıştır. Trenlerde %60 yerlilik şartı aranmış olup GoA-4 seviyesinde tam sürücüsüz olarak modern teknolojiye uygun tasarlanacaktır.',
                subText6: '· Toplam Proje İlerlemesi %63’tür.',
                projectInfo: {
                    title: 'Proje Bilgileri',
                    column1: ['Sözleşme Bedeli', 'Hat Uzunluğu', 'İstasyon Sayısı', 'Tasarım Hızı', 'Araç Sayısı', 'Yolcu Kapasitesi', 'TBM Sayısı', 'Tünel Kazı Miktarı (NATM + TBM)', 'Açık Kazı Miktarı', 'Beton Miktarı', 'Ray Miktarı'],
                    column2: ['2.488.489.457 TL', '2x15,4 Km (30,8 Km)', '11 adet', '90 km/saat', '4 dizi x 7 adet = 28 araç', '330.000 yolcu/gün', '2', '1.323.000 m³', '1.871.000 m³', '1.126.651 m³', '78.922 mt']
                }
            }
        },
        suggAndComp: {
            mainTitle: 'Öneri ve Şikayet',
            content: '* Kişisel bilgileriniz tarafımızca saklanmaktadır.'
        },
        data: {
            months: ['ocak', 'subat', 'mart', 'nisan', 'mayis', 'haziran', 'temmuz', 'agustos', 'eylul', 'ekim', 'kasim', 'aralik']
        }

    },
    en: {
        navbar: {
            parts: [
                {
                    title: "Mainpage"
                },
                {
                    title: "Technical Informations",
                    innerTitle: [
                        {
                            name: "Stations",
                            subTitle: ["Darıca Sahil", "Darıca Cumhuriyet Meydanı", "Farabi Devlet Hastanesi", "Gebze Gar", "Fatih Devlet Hastanesi", "Gebze Kent Meydanı", "Gebze Stadyumu", "Akse Sapağı", "Adliye", "Mutlukent", "OSB"]
                        },
                        {
                            name: "Warehouse Area"
                        },
                        {
                            name: "GDM With Numbers"
                        },
                        {
                            name: "Tender Information"
                        }
                    ]
                },
                {
                    title: "News"
                },
                {
                    title: "Media",
                    innerTitle: ["Resimler", "Videolar"]
                },
                {
                    title: "Progress Report"
                },
                {
                    title: "Contact"
                },

            ]
        },
        aboutus: {
            title: 'About Us'
        },
        footer: {
            cats: [
                {
                    name: 'Menu',
                    list: ['About Us', 'News', 'Contact']
                },
                {
                    name: 'Technical Informations',
                    list: ['Warehouse Area', 'Stations']
                },
                {
                    name: 'Media',
                    list: ['Images', 'Videos']
                }
            ]
        },
        copyright: '© 2023 Gebze || All rights reserved.',
        slider: {
            mainTitle: 'GEBZE - DARICA METRO LINE',
            subTitle: 'Life Begins When You Reach',
            buttonTitle: 'General Information'
        },
        home: {
            underHeader: {
                firstCard: {
                    title: '90 km/h',
                    desc: 'Design Speed'
                },
                secondCard: {
                    title: '330,000 Passengers / Day',
                    desc: 'Passenger Capacity'
                },
                thirdCard: {
                    title: '22Min/One Way',
                    desc: 'Project Route Travel Time'
                },
                fourthCard: {
                    title: '17 Vehicles',
                    desc: 'Train Parking Area'
                },
                fifthCard: {
                    title: '15,40 km',
                    desc: 'Line Length'
                },
            },
            infoCard: {
                title: 'ABOUT GEBZE - DARICA METRO',
                desc1: 'It is a metro project with 11 stations, consisting of two lines of 15.40 km, starting from Darıca Sahil and reaching Gebze Organized Industrial Zone.',
                desc2: 'The aim is to integrate the rail system into Gebze Marmaray Station by developing the transportation network by creating an alternative choice to the highway connection between Gebze Organized Industrial Zone and Gebze-Darıca districts.',
                buttonText: 'DETAILS'
            },
            detailedInfo: {
                info: {
                    title: 'GEBZE - DARICA METRO CONSTRUCTION CONTINUES...',
                    desc: 'The construction phase continues 24/7 and work continues at 76%.',
                    buttonText: 'DETAILS'
                },
                pieTitle: {
                    title: 'Physical Progress',
                    value: '76%'
                },
                progress: [
                    {
                        name: 'CPC',
                        percent: 100
                    },
                    {
                        name: 'NATM Main Line Tunnels Excavation Support',
                        percent: 99
                    },
                    {
                        name: 'NATM Main Line Tunnels Permanent Coating',
                        percent: 82
                    },
                    {
                        name: 'Station Excavation-Support Works',
                        percent: 95
                    },
                    {
                        name: 'Station Permanent Reinforced Concrete Works',
                        percent: 29
                    },
                    {
                        name: 'Storage Area Excavation-Fill Art Structures Reinforced Concrete Building Works',
                        percent: 93
                    },
                    {
                        name: 'Warehouse Area Rail Works',
                        percent: 32
                    },
                    {
                        name: 'Main Line Rail Works',
                        percent: 16
                    },
                    {
                        name: 'Architect Works',
                        percent: 8
                    },
                    {
                        name: 'Power Supply',
                        percent: 4
                    },
                    {
                        name: 'Electromechanical Works',
                        percent: 13
                    },
                ]
            },
            news: {
                title: 'NEWS',
                videos: [
                    {
                        title: 'Gebze OSB - First Rail Welding Ceremony of Darıca Metro Line',
                        date: '16.10.2023'
                    },
                    {
                        title: 'Darıca Sahil Station TBM Opening Ceremony',
                        date: '14.06.2021'
                    },
                    {
                        title: 'Gebze Train Station TBM Exit Ceremony',
                        date: '12.10.2022'
                    },
                    {
                        title: 'Ministry of Transport and Infrastructure',
                        date: '24.08.2022'
                    },
                    {
                        title: 'GEBZE - DARICA METRO LINE WILL BE IN SERVICE IN 2023',
                        date: '22.08.2022'
                    },
                ]
            },
            newsletter: {
                title: 'Suggestion and Complaint',
                desc: 'For your Suggestions and Complaints, please fill out the form by clicking the button on the side.',
            }
        },
        technicInfos: {
            mainTitle: 'GEBZE DARICA METRO',
            stations: {
                title: 'Stations',
                subCats: [
                    {
                        id: 1,
                        title: 'Darıca Beach',
                        desc: `Darıca Beach Station, which is being constructed with the Cut-and-Cover method, is located 
                        north of the Şehit Cevher Dudayev Park in Darıca Beach. The station is designed as a buried structure 
                        to maintain the visual integrity of the beach. The depth of the station is 10 meters, and the construction 
                        area is 4,436 square meters.`
                    },
                    {
                        id: 2,
                        title: 'Darıca Republic Square',
                        desc: `Darıca Republic Square Station is being constructed using the New Austrian Tunneling Method (NATM) 
                        to replace the existing Republic Square Park located between İstasyon Street and Kavala Street. The concourse 
                        structure of the station, tunnel ventilation shafts, and the visible parts of stair shafts are designed to 
                        harmonize with the urban fabric and minimize interference with the park. The depth of the station is 32 meters, 
                        and the construction area is 5,694 square meters.`
                    },
                    {
                        id: 3,
                        title: 'Farabi State Hospital',
                        desc: `The Cut-and-Cover concourse structure of Farabi State Hospital Station is located between Cevahir Street 
                        and Karlı Street. There will be an exit to Farabi State Hospital through a Pedestrian Tunnel connected to the concourse 
                        structure. Peron Tunnels, Stair Tunnels, and the Pedestrian Tunnel are constructed using the New Austrian Tunneling Method (NATM). 
                        The depth of the station is 37 meters, and the construction area is 8,167 square meters.`
                    },
                    {
                        id: 4,
                        title: 'Gebze Station',
                        desc: `Gebze Station is located between Farabi State Hospital and Fatih State Hospital Stations. 
                        It will be integrated with the Marmaray and High-Speed Train Lines. The station and the adjacent turnout-parking structure 
                        are located south of Ethem Bey Street, in alignment with the Gebze Marmaray station entrance bridge. 
                        The station and turnout structure are being constructed using the Cut-and-Cover method. The depth of the station is 29 meters, 
                        and the construction area is 26,988 square meters.`
                    },
                    {
                        id: 5,
                        title: 'Fatih State Hospital',
                        desc: `The station is located at the intersection of Istanbul Street and İstasyon Street. It is constructed using the New Austrian Tunneling Method (NATM). 
                        The station has 2 entrance/exit structures. The depth of the station is 52 meters, and the construction area is 4,301 square meters.`
                    },
                    {
                        id: 6,
                        title: 'Gebze Civic Square',
                        desc: `Gebze Civic Square Station is one of the busiest stations on the line as it is the focal point of Gebze 
                        and has one of the highest projected passenger numbers. The station is located in the old town square just south of the 
                        new and thriving Gebze Civic Square, as well as the historical Çoban Mustafa Pasha Mosque. 
                        To avoid interfering with the new square and to allow the old square to come back to life, the station was located in the old town square. 
                        Thus, the entire area could be redeveloped. The station is constructed using the New Austrian Tunneling Method (NATM). 
                        There are 2 entrance/exit structures to Civic Square and the Jewelers’ Bazaar. 
                        The depth of the station is 50 meters, and the construction area is 7,307 square meters.`
                    },
                    {
                        id: 7,
                        title: 'Gebze Stadium',
                        desc: `Gebze Stadium Station is positioned to serve the surrounding sports complexes, schools, and the municipal headquarters. 
                        The station is located on the open football field located between Şehit Mevlüt Duru and 952/4 Streets. Since the station is one of the Cut-and-Cover stations, 
                        the ventilation shafts and other surface structures are located within the cut-and-cover area. The depth of the station is 24 meters, 
                        and the construction area is 6,002 square meters.`
                    },
                    {
                        id: 8,
                        title: 'Akse Junction',
                        desc: `Akse Junction is a "pivot area" in Gebze, which is a very busy area. The station is located at the beginning of Issıkgöl Street on the north side. 
                        It is located between Gebze Stadium and Courthouse Stations. It is constructed using the New Austrian Tunneling Method (NATM). The depth of the station is 29 meters, 
                        and the construction area is 5,802 square meters.`
                    },
                    {
                        id: 9,
                        title: 'Courthouse',
                        desc: `Courthouse Station is located to serve an area with high development potential due to the Courthouse, 
                        the market area, schools, and other investments made by the Gebze Municipality. The station and the adjacent turnout-parking structure are located on the Fen Works land owned by the Municipality. 
                        Integration with the planned Gebze – Sabiha Gökçen Airport project will be provided through Courthouse Station. 
                        The depth of the station is 26 meters, and the construction area is 25,169 square meters.`
                    },
                    {
                        id: 10,
                        title: 'Mutlukent',
                        desc: `Mutlukent Station is positioned in the area parallel to the west of 2415 Street with a cut-and-cover method. 
                        It is located on parcels 4784/2 and 4778/1. The station is located in this area to serve the developing Mutlukent settlement and Gebze Olympic Sports Facilities. 
                        The depth of the station is 24 meters, and the construction area is 6,569 square meters.`
                    },
                    {
                        id: 11,
                        title: 'OSB',
                        desc: `It is constructed using the New Austrian Tunneling Method (NATM). 
                        There is also a parking and stopping area for OSB service vehicles and ring bus services. 
                        The depth of the station is 50 meters, and the construction area is 6,668 square meters.`
                    },
                ],
            },
            warehouse: {
                title: 'Depot Area',
                desc: `The Depot and Control Center is located approximately 1 km north of the OSB Station. 
                It is being constructed on a total area of 92,984 square meters and has a capacity for 17 metro vehicles.`
            },
            gdmNumbers: {
                title: 'GDM in Numbers',
                part1: {
                    title: 'Main Line Features',
                    content: [`Design Speed: 90 km/h`, `Operating Speed: 80 km/h`, `Passenger Capacity: 330,000 Passengers/Day`, `Travel Time on the Project Route: 22 Minutes/One Way`,
                        `TBM Main Line Tunnel Length: 6.83 km`, `NATM Main Line Tunnel Length: 20.52 km`, `6 Cut-and-Cover Type Stations`, `5 Cut-and-Cover Type Stations`]
                },
                part2: {
                    title: 'Depot Area',
                    content: [`Train Parking Area for 17 Vehicles`, `45 Turnouts`, `Total Excavation Amount: 916,664 m3`]
                }
            },
            ihaleInfos: {
                title: 'Tender Information',
                subTitle: 'Project Tender Information',
                projectName: 'CONSTRUCTION AND PROCUREMENT OF ELECTROMECHANICAL SYSTEMS FOR GEBZE DARICA METRO CONSTRUCTION AND COMMISSIONING WORKS',
                constructor: 'EZE CONSTRUCTION INC.',
                consultant: 'TÜMAŞ TÜRK ENGINEERING CONSULTING AND CONTRACTING INC.',
                projectNameTitle: 'Project Name',
                constructorTitle: 'The contractor',
                consultantTitle: 'Consultant'
            }
        },
        news: {
            detail: {
                share: 'Share'
            }
        },
        images: {
            mainTitle: "Images",
            content: [
                {
                    id: 1,
                    title: "Darica Beach Photo Gallery"
                },
                {
                    id: 2,
                    title: "Darica Republic Square Photo Gallery"
                },
                {
                    id: 3,
                    title: "Farabi State Hospital Photo Gallery"
                },
                {
                    id: 4,
                    title: "Gebze Train Station Photo Gallery"
                },
                {
                    id: 5,
                    title: "Fatih State Hospital Photo Gallery"
                },
                {
                    id: 6,
                    title: "Gebze Keny Square Photo Gallery"
                }
            ]
        },
        contact: {
            infos: {
                title: 'Contact Informations',
                location: 'Hakkı Turayliç Str. No:5 06338 Emek/Çankaya/ANKARA',
                mail: 'asdags@gmail.com',
                phone: '0312 203 10 00'
            },
            form: {
                title: 'Send Message',
                name: 'Your Name',
                surname: 'Your Surname',
                emailaddress: 'Your Email Address',
                phoneNumber: 'Your Phone Number',
                subject: 'Subject',
                buttonText: 'Send'
            }
        },
        aboutus: {
            title: 'About Us',
            project: {
                title: 'PROJECT SCOPE AND TECHNICAL SPECIFICATIONS',
                content: {
                    column1: ['Line Length', 'Number of Stations', 'Line Structure', 'Line Feature', 'Design Speed (Maximum)', 'Connection Points with Other Projects', 'Vehicle Parking Capacity', 'Vehicle Specifications', 'Transport Capacity (Design)', 'Daily Passenger Count', 'Travel Time'],
                    column2: ['15.4 km', '11 Stations - 1 Depot Area', '6,782 m TBM, 24,739 m NATM', 'Double Tube', '90 km/h', 'Gebze Gar Station: Marmaray Transfer, Adliye Station: Sabiha Gökçen Metro Integration', 'Phase 1 (17) – Phase 2 (15)', 'GO4 Autonomous 4-Car Trainsets', '1080 passengers/train/trip', '188,982 passengers/day (2035)', '40 minutes'],
                },
                subContent: 'The economic gain between 2022-2047 upon completion of the Metro Line;',
                innerContent1: 'Road Maintenance and Operation Gain = €356.12 Million',
                innerContent2: 'Accident Gain = €7.49 Million',
                innerContent3: 'External Benefits (Air Pollution, Climate Change, Noise, Nature and Greenfield Costs, Biodiversity, Soil and Water Pollution) = €9.47 Million',
                innerContent4: 'Time Gain = €2,361.037 Million',
                outroContent: 'In total, the 25-year economic gain for the metro line will be €2,734.12 Million.'
            }
        },
        toBeCont: {
            mainTitle: "Metro Construction is Ongoing...",
            content: {
                title: "CURRENT STATUS OF THE PROJECT:",
                subText1: "· The Gebze-Darica Metro construction work was awarded by Kocaeli Metropolitan Municipality on 18.05.2018, and the contract was signed with the contractor on 12.06.2018.",
                subText2: "· Approximately 2% progress has been achieved within the scope of the project, and Kocaeli Metropolitan Municipality requested that the project be undertaken by our Ministry.",
                subText3: "· The project was taken over by our Ministry with the Presidential Decree dated 17.09.2019 and numbered 2019/1550 regarding the transfer of the project to our Ministry.",
                subText4: "· A transfer protocol was signed between Kocaeli Metropolitan Municipality and our Ministry on 18.10.2019, and a transfer contract was signed with the contractor on 12.12.2019 after the transfer protocol. The total physical progress recorded since the transfer contract is 76%.",
                subText5: "· On July 22, 2020, a tender for the purchase of 28 vehicles was held, and the contract was signed on November 4, 2020. The trains will have a requirement of 60% localization and will be designed in compliance with Level GoA-4 as fully driverless with modern technology.",
                subText6: "· The overall project progress is 63%.",
                projectInfo: {
                    title: "Project Information",
                    column1: ["Contract Value", "Line Length", "Number of Stations", "Design Speed", "Number of Vehicles", "Passenger Capacity", "TBM Count", "Tunnel Excavation Quantity (NATM + TBM)", "Open Excavation Quantity", "Concrete Quantity", "Rail Quantity"],
                    column2: ["2,488,489,457 TL", "2x15.4 Km (30.8 Km)", "11 units", "90 km/h", "4 sets x 7 units = 28 vehicles", "330,000 passengers/day", "2", "1,323,000 m³", "1,871,000 m³", "1,126,651 m³", "78,922 mt"]
                }
            }
        },
        suggAndComp: {
            mainTitle: 'Suggestion and Complaint',
            content: '* Your personal information is stored by us.'
        },
        data: {
            months: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
        }


    },
};