import React from 'react'
import style from './newsdetail.module.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import { useLocation } from 'react-router-dom';
import { landData } from '../../Helpers/Lang';
import dummyImage from '../../assets/img/home/news/dummy.png'
import { FacebookIcon, TwitterIcon } from '../../assets/icons';

const NewsDetail = ({ route }) => {

    const currentLang = localStorage.getItem('lang');
    const location = useLocation();
    const pathName = location.pathname.split('/')[2];
    const dataNews = {
        title: landData[currentLang].home.news.title,
        data: [
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[0].title,
                date: landData[currentLang].home.news.videos[0].date,
                path: '1'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[1].title,
                date: landData[currentLang].home.news.videos[1].date,
                path: '2'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[2].title,
                date: landData[currentLang].home.news.videos[2].date,
                path: '3'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[3].title,
                date: landData[currentLang].home.news.videos[3].date,
                path: '4'
            },
            {
                img: dummyImage,
                title: landData[currentLang].home.news.videos[4].title,
                date: landData[currentLang].home.news.videos[4].date,
                path: '5'
            }
        ]
    }

    return (
        <PageWrapper
            title={Metadata[currentLang].home.title}
            desc={Metadata[currentLang].home.desc}
            whiteHeader
            path={route}
        >
            <div className={style.newsDetailWrapper}>
                <div className={style.nDetailWrap}>
                    <img src={dataNews.data[pathName - 1].img} alt="" />
                    <h3>{dataNews.data[pathName - 1].title}</h3>
                    <div className={style.shareSocial}>
                        <a href="#">
                            {landData[currentLang].news.detail.share}
                        </a>
                        <a href="#">
                            <FacebookIcon color={'blue'} />
                        </a>
                        <a href="#">
                            <TwitterIcon color={'blue'} />
                        </a>
                    </div>
                    <iframe
                        width="100%"
                        height="500"
                        src="https://www.youtube.com/embed/CGUsI2DoE2k?si=N5AUioE-V4t2KX_O"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                    </iframe>
                    {/* <video>
                        <source src={""} type="video/mp4" />
                    </video> */}
                </div>
            </div>
        </PageWrapper >
    )
}

export default NewsDetail