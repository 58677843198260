import React from 'react'
import style from './assetmedia.module.scss';
import { Metadata } from '../../Helpers/Metadata';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { landData } from '../../Helpers/Lang';
import image1 from '../../assets/img/images/image1.png'
import image2 from '../../assets/img/images/image2.png'
import image3 from '../../assets/img/images/image3.png'
import { useNavigate } from 'react-router-dom';

const AssetMedia = ({ route }) => {

  const currentLang = localStorage.getItem('lang');
  const nav = useNavigate();

  const dataImages = [
    {
      id: landData[currentLang].images.content[0].id,
      title: landData[currentLang].images.content[0].title,
      image: image1
    },
    {
      id: landData[currentLang].images.content[1].id,
      title: landData[currentLang].images.content[1].title,
      image: image2
    },
    {
      id: landData[currentLang].images.content[2].id,
      title: landData[currentLang].images.content[2].title,
      image: image3
    },
    {
      id: landData[currentLang].images.content[3].id,
      title: landData[currentLang].images.content[3].title,
      image: image1
    },
    {
      id: landData[currentLang].images.content[4].id,
      title: landData[currentLang].images.content[4].title,
      image: image2
    },
    {
      id: landData[currentLang].images.content[5].id,
      title: landData[currentLang].images.content[5].title,
      image: image3
    }
  ]

  const handleClick = (item) =>{
    nav(`/assetmedia/${item.id}`)
  }

  return (
    <>
      <PageWrapper
        title={Metadata[currentLang].home.title}
        desc={Metadata[currentLang].home.desc}
        whiteHeader
        path={route}
      >
        <div className={style.assetImagesWrapper}>
          <Breadcrumbs path={route} />
          <div className={style.assetImagesInnerWrapper}>
            <h3>{landData[currentLang].images.mainTitle}</h3>
            <div className={style.assetImagesContent}>
              {
                dataImages.map((item, index) => {
                  return (
                    <button
                      key={item.id}
                      onClick={(e) => handleClick(item)}
                    >
                      <img src={item.image} alt="" />
                      <span>{item.title}</span>
                    </button>
                  );
                })
              }
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  )
}

export default AssetMedia