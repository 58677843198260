import Preloader from "../../components/Preloader/Preloader";
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata'
import style from './home.module.scss'
import UnderHeader from "../../components/HomeComponents/UnderHeader/UnderHeader";
import InfoCard from "../../components/HomeComponents/InfoCard/InfoCard";
import DetailedInfo from "../../components/HomeComponents/detailedInfo/DetailedInfo";
import NewsComp from "../../components/HomeComponents/newsComponent/NewsComp";
import Newsletter from "../../components/HomeComponents/newsletter/Newsletter";
import { useEffect, useState } from "react";

const Home = ({ route }) => {

    const currentLang = localStorage.getItem('lang')

    

    return (
        <>
            <PageWrapper
                title={Metadata[currentLang].home.title}
                desc={Metadata[currentLang].home.desc}
                whiteHeader
                path={route}
            >
                <div className={style.homeWrapper}>
                    <UnderHeader />
                    <InfoCard />
                    <DetailedInfo />
                    <NewsComp />
                    <Newsletter />
                </div>
            </PageWrapper>
        </>
    )
}

export default Home