import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes";

function App() {

  const [isLanguageLoad, setLanguageLoad] = useState(false);

  useEffect(() => {
    const currentLanguage = localStorage.getItem('lang');

    if (!currentLanguage) {
      localStorage.setItem('lang', 'en');
      setLanguageLoad(true);
    } else {
      setLanguageLoad(true);
    }
  }, []);

  
  return (
    <>
      <BrowserRouter>
        {/* <ScrollTop /> */}
        {/* {!isLoad && <Preloader />} */}

        <Routes>
          {isLanguageLoad &&
            routes.map(route => {
              return (
                <Route
                  path={route.path}
                  element={
                    route.component
                    // route.protected ? (
                    //   <ProtectedRoute>{route.component}</ProtectedRoute>
                    // ) : (
                    //   route.component
                    // )
                  }
                />
              );
            })}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
