import React from 'react'
import style from './warehouse.module.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { Metadata } from '../../Helpers/Metadata';
import { useLocation } from 'react-router-dom';
import { landData } from '../../Helpers/Lang';

const Warehouse = ({ route }) => {

    const currentLang = localStorage.getItem('lang');
    const location = useLocation();

    return (
        <>
            <PageWrapper
                title={Metadata[currentLang].home.title}
                desc={Metadata[currentLang].home.desc}
                whiteHeader
                path={route}
            >
                <div className={style.warehouseWrapper}>
                    <Breadcrumbs path={location.pathname ? location.pathname : route} />
                    <div className={style.warehouseInnerWrapper}>
                        <h3>{landData[currentLang].technicInfos.warehouse.title}</h3>
                        <span>{landData[currentLang].technicInfos.warehouse.desc}</span>
                    </div>
                </div>
            </PageWrapper>
        </>
    )
}

export default Warehouse