import React from 'react'
import style from './assetmediadetail.module.scss';
import { useLocation } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import { landData } from '../../Helpers/Lang';
import image1 from '../../assets/img/images/image1.png'
import image2 from '../../assets/img/images/image2.png'
import image3 from '../../assets/img/images/image3.png'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const AssetMediaDetail = ({ route }) => {

    const currentLang = localStorage.getItem('lang');
    const location = useLocation();
    const pathNa = location.pathname.split('/')[2];

    // buraya uzunca bi galeri datası gelecek tabi

    const dataImages = [
        {
            id: landData[currentLang].images.content[0].id,
            title: landData[currentLang].images.content[0].title,
            images: [image1, image2, image1, image2, image1, image2]
        },
        {
            id: landData[currentLang].images.content[1].id,
            title: landData[currentLang].images.content[1].title,
            images: [image2, image3, image2, image3, image2, image3]
        },
        {
            id: landData[currentLang].images.content[2].id,
            title: landData[currentLang].images.content[2].title,
            images: [image3, image1, image3, image1, image3, image1]
        },
        {
            id: landData[currentLang].images.content[3].id,
            title: landData[currentLang].images.content[3].title,
            images: [image1, image2, image1, image2, image1, image2]
        },
        {
            id: landData[currentLang].images.content[4].id,
            title: landData[currentLang].images.content[4].title,
            images: [image2, image3, image2, image3, image2, image3]
        },
        {
            id: landData[currentLang].images.content[5].id,
            title: landData[currentLang].images.content[5].title,
            images: [image3, image1, image3, image1, image3, image1]
        }
    ]

    return (
        <PageWrapper
            title={Metadata[currentLang].home.title}
            desc={Metadata[currentLang].home.desc}
            whiteHeader
            path={route}
        >
            <div className={style.assetmediaDetailWrapper}>
                <Breadcrumbs path={route} />
                <div className={style.amInnerDetailWrapper}>
                    <h4>{dataImages[pathNa - 1].title}</h4>
                    <div className={style.amInnerDetailImages}>
                        {
                            dataImages[pathNa - 1].images.map((i, idx) => {
                                return (
                                    <button
                                        key={idx}
                                        // lightboc ya da fancybox gelecak tıklayınca 
                                    >
                                        <img src={i} alt="innerImage" />
                                    </button>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </PageWrapper >
    )
}

export default AssetMediaDetail