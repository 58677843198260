import {
  Home,
  Stations,
  News,
  AssetMedia,
  Reports,
  Contact,
  Warehouse,
  TenderInfos,
  AboutUs
} from '../containers';
import Gdminnumbers from '../containers/GDM/Gdminnumbers';
import NewsDetail from '../containers/NewsDetail/NewsDetail';
import AssetMediaDetail from '../containers/AssetMediaDetail/AssetMediaDetail';
import SuggAndComp from '../containers/SuggestionAndComplaint/SuggAndComp';
import YapimAsamasi from '../containers/YapimAsamasi/YapimAsamasi';

export const routes = [
  {
    title: 'Home',
    path: '*',
    component: <Home route={''} />,
    protected: false,
  },
  {
    title: 'Stations',
    path: '/stations',
    component: <Stations route={'/stations'} />,
    protected: false,
  },
  {
    title: 'Warehouse',
    path: '/warehouse',
    component: <Warehouse route={'/warehouse'} />,
    protected: false,
  },
  {
    title: 'GDM',
    path: '/gdm',
    component: <Gdminnumbers route={'/gdm'} />,
    protected: false,
  },
  {
    title: 'TenderInfos',
    path: '/tenderinfos',
    component: <TenderInfos route={'/tenderinfos'} />,
    protected: false,
  },
  {
    title: 'Stations',
    path: '/stations/:id',
    component: <Stations route={'/stations/:id'} />,
    protected: false,
  },
  {
    title: 'Yapım Asamasi',
    path: '/yapim',
    component: <YapimAsamasi route={'/yapim'} />,
    protected: false,
  },
  {
    title: 'Öneri ve Şikayet',
    path: '/onerisikayet',
    component: <SuggAndComp route={'/onerisikayet'} />,
    protected: false,
  },
  {
    title: 'News',
    path: '/news',
    component: <News route={'/news'} />,
    protected: false,
  },
  {
    title: 'News Detail',
    path: '/news/:id',
    component: <NewsDetail route={'/news'} />,
    protected: false,
  },
  {
    title: 'AssetMedia',
    path: '/assetmedia',
    component: <AssetMedia route={'/assetmedia'} />,
    protected: false,
  },
  {
    title: 'AssetMedia Detail',
    path: '/assetmedia/:id',
    component: <AssetMediaDetail route={'/assetmedia'} />,
    protected: false,
  },
  {
    title: 'Aboutus',
    path: '/aboutus',
    component: <AboutUs route={'/aboutus'} />,
    protected: false,
  },
  {
    title: 'Reports',
    path: '/reports',
    component: <Reports route={'/reports'} />,
    protected: false,
  },
  {
    title: 'Contact',
    path: '/contact',
    component: <Contact route={'/contact'} />,
    protected: false,
  }
];
