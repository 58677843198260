import React, { useState } from 'react'
import style from './ststions.module.scss';
import { Metadata } from '../../Helpers/Metadata';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { landData } from '../../Helpers/Lang';
import MetroLogo from '../../assets/img/navbar/ulastirmaLogo.png'
import { useLocation } from 'react-router-dom';


const Stations = ({ route }) => {

    const currentLang = localStorage.getItem('lang');
    const location = useLocation();
    const newLocation = location.pathname.split('/')[2];
    
    const [activeTab, setActiveTab] = useState(newLocation !== undefined ? Number(newLocation) : 1);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    const dataTabs =
    {
        stations: {
            title: 'İstasyonlar',
            subCats: [
                {
                    id: 1,
                    title: landData[currentLang].technicInfos.stations.subCats[0].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[0].desc
                },
                {
                    id: 2,
                    title: landData[currentLang].technicInfos.stations.subCats[1].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[1].desc
                },
                {
                    id: 3,
                    title: landData[currentLang].technicInfos.stations.subCats[2].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[2].desc
                },
                {
                    id: 4,
                    title: landData[currentLang].technicInfos.stations.subCats[3].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[3].desc
                },
                {
                    id: 5,
                    title: landData[currentLang].technicInfos.stations.subCats[4].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[4].desc
                },
                {
                    id: 6,
                    title: landData[currentLang].technicInfos.stations.subCats[5].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[5].desc
                },
                {
                    id: 7,
                    title: landData[currentLang].technicInfos.stations.subCats[6].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[6].desc
                },
                {
                    id: 8,
                    title: landData[currentLang].technicInfos.stations.subCats[7].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[7].desc
                },
                {
                    id: 9,
                    title: landData[currentLang].technicInfos.stations.subCats[8].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[8].desc
                },
                {
                    id: 10,
                    title: landData[currentLang].technicInfos.stations.subCats[9].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[9].desc
                },
                {
                    id: 11,
                    title: landData[currentLang].technicInfos.stations.subCats[10].title,
                    desc: landData[currentLang].technicInfos.stations.subCats[10].desc
                },
            ],
        },
    }


    return (
        <>
            <PageWrapper
                title={Metadata[currentLang].home.title}
                desc={Metadata[currentLang].home.desc}
                whiteHeader
                path={route}
            >
                <div className={style.stationsWrapper}>
                    <Breadcrumbs path={location.pathname ? location.pathname : route} />
                    <div className={style.stationsInnerWrapper}>
                        <div className={style.verticalTabContainer}>

                            <div className={style.verticalTabButtons}>
                                <div className={style.verticalTabTopContainer}>
                                    <img src={MetroLogo} alt="mini_logo" />
                                    <span>{landData[currentLang].technicInfos.mainTitle}</span>
                                </div>
                                {dataTabs.stations.subCats.map((subCat) => (
                                    <button
                                        key={subCat.id}
                                        onClick={() => handleTabClick(subCat.id)}
                                        className={activeTab === subCat.id ? style.active : ''}
                                    >
                                        {subCat.title}
                                    </button>
                                ))}
                            </div>

                            <div className={style.verticalTabContent}>
                                {dataTabs.stations.subCats.map((subCat) => (
                                    <div
                                        data-text={subCat.title}
                                        className={style.innerTabContent}
                                        key={subCat.id}
                                        style={{ display: activeTab === subCat.id ? 'block' : 'none' }}
                                    >
                                        {subCat.desc}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </PageWrapper>
        </>
    )
}

export default Stations