import React from 'react'
import { FacebookIcon, MailIcon, TwitterIcon } from '../../assets/icons'
import style from './footer.module.scss'
import FooterImage1 from '../../assets/img/footer/footerImage1.png'
import FooterImage2 from '../../assets/img/footer/footerImage2.png'
import { landData } from '../../Helpers/Lang'
import miniLogo from '../../assets/img/navbar/ulastirmaLogo.png'

const Footer = () => {

  const currentLang = localStorage.getItem('lang')

  const footerData = [
    {
      title: landData[currentLang].footer.cats[0].name,
      list: [
        {
          name: landData[currentLang].footer.cats[0].list[0],
          link: '/aboutus',
        },
        {
          name: landData[currentLang].footer.cats[0].list[1],
          link: '/news',
        },
        {
          name: landData[currentLang].footer.cats[0].list[2],
          link: '/contact',
        }
      ]
    },
    {
      title: landData[currentLang].footer.cats[1].name,
      list: [
        {
          name: landData[currentLang].footer.cats[1].list[0],
          link: '/warehouse',
        },
        {
          name: landData[currentLang].footer.cats[1].list[1],
          link: '/stations',
        }
      ]
    },
    {
      title: landData[currentLang].footer.cats[2].name,
      list: [
        {
          name: landData[currentLang].footer.cats[2].list[0],
          link: '/assetmedia',
        },
        {
          name: landData[currentLang].footer.cats[2].list[1],
          link: '/assetmedia',
        }
      ]
    }
  ]

  return (
    <footer className={style.footer}>
      <div className={style.miniLogo}>
        <img src={miniLogo} alt="footer_minimage" />
      </div>
      <div className={style.wrapper}>
        <div className={style.footerTop}>
          <div className={style.footerLogo}>
            <img src={FooterImage1} alt="footer_image" />
          </div>
          <div className={style.necessaryLinks}>
            {
              footerData.map((item, idx) => {
                return (
                  <div className={style.column} key={idx}>
                    <h6>{item.title}</h6>
                    <ul>
                      {item.list.map((i, index) => {
                        return (
                          <li key={index}>
                            <a href={i.link} target={'_blank'}>
                              {i.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )
              })
            }
          </div>
          <div className={style.footerLogo}>
            <img src={FooterImage2} alt="footer_image" />
          </div>
        </div>
        <div className={style.footerBottom}>
          <div className={style.socialIcons}>
            <a href="#" target={'_blank'}>
              <FacebookIcon color={'#ADADAD'} />
            </a>
            <a href="#" target={'_blank'}>
              <TwitterIcon color={'#ADADAD'} />
            </a>
            <a href="#" target={'_blank'}>
              <MailIcon color={'#ADADAD'} />
            </a>
          </div>
          <span>{landData[currentLang].copyright}</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer