import React from 'react'
import style from './underheader.module.scss'
import { landData } from '../../../Helpers/Lang';
import First from '../../../assets/img/home/underheader/fifth.png'
import Second from '../../../assets/img/home/underheader/second.png'
import Third from '../../../assets/img/home/underheader/third.png'
import Fourth from '../../../assets/img/home/underheader/fourth.png'
import Fifth from '../../../assets/img/home/underheader/fifth.png'

const DetailCard = () => {

    const currentLang = localStorage.getItem('lang')

    const data = [
        {
            id: 1,
            image: First,
            title: landData[currentLang].home.underHeader.firstCard.title,
            desc: landData[currentLang].home.underHeader.firstCard.desc
        },
        {
            id: 2,
            image: Second,
            title: landData[currentLang].home.underHeader.secondCard.title,
            desc: landData[currentLang].home.underHeader.secondCard.desc
        },
        {
            id: 3,
            image: Third,
            title: landData[currentLang].home.underHeader.thirdCard.title,
            desc: landData[currentLang].home.underHeader.thirdCard.desc
        },
        {
            id: 4,
            image: Fourth,
            title: landData[currentLang].home.underHeader.fourthCard.title,
            desc: landData[currentLang].home.underHeader.fourthCard.desc
        },
        {
            id: 5,
            image: Fifth,
            title: landData[currentLang].home.underHeader.fifthCard.title,
            desc: landData[currentLang].home.underHeader.fifthCard.desc
        },
    ]

    return (
        data.map((item) => {
            return (
                <div className={style.wrapperInner}>
                    <img src={item.image} alt="_" />
                    <span>{item.title}</span>
                    <span>{item.desc}</span>
                </div>
            )
        })
    );
}


const UnderHeader = () => {

    const currentLang = localStorage.getItem('lang')

    return (
        <div className={style.underHeaderWrapper}>
            <DetailCard />
        </div>
    )
}

export default UnderHeader