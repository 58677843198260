import React from 'react'
import style from './yapimasamasi.module.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { landData } from '../../Helpers/Lang';

const YapimAsamasi = ({ route }) => {

    const currentLang = localStorage.getItem('lang');

    const dataYapim = {

        mainTitle: landData[currentLang].toBeCont.mainTitle,
        content: {
            title: landData[currentLang].toBeCont.content.title,
            subText1: landData[currentLang].toBeCont.content.subText1,
            subText2: landData[currentLang].toBeCont.content.subText2,
            subText3: landData[currentLang].toBeCont.content.subText3,
            subText4: landData[currentLang].toBeCont.content.subText4,
            subText5: landData[currentLang].toBeCont.content.subText5,
            subText6: landData[currentLang].toBeCont.content.subText6,
            projectInfo: {
                title: landData[currentLang].toBeCont.content.projectInfo.title,
                column1: landData[currentLang].toBeCont.content.projectInfo.column1,
                column2: landData[currentLang].toBeCont.content.projectInfo.column2,
            }
        }
    }

    return (
        <>
            <PageWrapper
                title={Metadata[currentLang].home.title}
                desc={Metadata[currentLang].home.desc}
                whiteHeader
                path={route}
            >
                <div className={style.yapimWrapper}>
                    <Breadcrumbs path={route} />
                    <div className={style.yapimInnerWrapper}>
                        <h3>{dataYapim.mainTitle}</h3>
                        <div className={style.yapimContent}>
                            <h5>{dataYapim.content.title}</h5>
                            <h6>{dataYapim.content.subText1}</h6>
                            <h6>{dataYapim.content.subText2}</h6>
                            <h6>{dataYapim.content.subText3}</h6>
                            <h6>{dataYapim.content.subText4}</h6>
                            <h6>{dataYapim.content.subText5}</h6>
                        </div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{dataYapim.content.projectInfo.title}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataYapim.content.projectInfo.column1.map((item, index) =>
                                    (
                                        <tr key={index}>
                                            <th scope="row">{item}</th>
                                            <td>{dataYapim.content.projectInfo.column2[index]}</td>
                                        </tr>
                                    )
                                    )
                                }
                            </tbody>
                        </table>
                        <h6>{dataYapim.content.subText6}</h6>
                    </div>
                </div>
            </PageWrapper>
        </>
    )
}

export default YapimAsamasi