import React from 'react'
import style from './infocard.module.scss'
import { landData } from '../../../Helpers/Lang'
import { useNavigate } from 'react-router-dom'


const InfoCard = () => {

    const currentLang = localStorage.getItem('lang')
    const navigation = useNavigate();

    return (
        <div className={style.infocardWrapper}>
            <h4>{landData[currentLang].home.infoCard.title}</h4>
            <span>{landData[currentLang].home.infoCard.desc1}</span>
            <span>{landData[currentLang].home.infoCard.desc2}</span>
            <button onClick={() => navigation('/aboutus')}>{landData[currentLang].home.infoCard.buttonText}</button>
        </div>
    )
}

export default InfoCard