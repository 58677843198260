import { Fade } from 'react-reveal'
import style from './header.module.scss'
import { useRef, useState } from 'react';
import Slider from 'react-slick';
import Home from '../../assets/img/home/HomeSlider.png'
import Dummy from '../../assets/img/home/news/dummy.png'
import { landData } from '../../Helpers/Lang';

const Header = ({ path, whichPage }) => {

  const slideRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    nextArrow: <></>,
    prevArrow: <></>,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const currentLanguage = localStorage.getItem('lang');
  const newsFirstInner = whichPage.split("/")[1];
  const newsInner = whichPage.split("/")[2];

  if (newsInner && newsFirstInner === "news") {
    return null;
  }
  return (
    <Fade Fade distance="100px" left >
      <div className={style.sliderWrapper}>
        <Slider className={style.slider} ref={slideRef} {...settings}>
          <div className={style.imageWrapper}>
            {
              whichPage === '/*'
                ?
                <>
                  <img src={Home} alt="home_slider_" />
                  <div className={style.imageWrapperInfo}>
                    <Fade distance="200px" top>
                      <h3>{landData[currentLanguage].slider.mainTitle}</h3>
                    </Fade>
                    <Fade distance="600px" bottom>
                      <span>{landData[currentLanguage].slider.subTitle}</span>
                    </Fade>
                    <Fade distance="1000px" right>
                      <a href='/aboutus'>{landData[currentLanguage].slider.buttonTitle}</a>
                    </Fade>
                  </div>
                </>
                :
                <img src={Dummy} alt="home_slider_" />
            }
          </div>
        </Slider>
      </div>
    </Fade>
  )
}

export default Header