import React from 'react'
import style from './tenderinfos.module.scss';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import { landData } from '../../Helpers/Lang';

const TenderInfos = ({ route }) => {

    const currentLang = localStorage.getItem('lang');

    return (
        <>
            <PageWrapper
                title={Metadata[currentLang].home.title}
                desc={Metadata[currentLang].home.desc}
                whiteHeader
                path={route}
            >
                <div className={style.tenderWrapper}>
                    <Breadcrumbs path={route} />
                    <div className={style.tenderInnerWrapper}>
                        <h3>{landData[currentLang].technicInfos.ihaleInfos.title}</h3>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{landData[currentLang].technicInfos.ihaleInfos.subTitle}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{landData[currentLang].technicInfos.ihaleInfos.projectNameTitle}</th>
                                    <td>{landData[currentLang].technicInfos.ihaleInfos.projectName}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{landData[currentLang].technicInfos.ihaleInfos.constructorTitle}</th>
                                    <td>{landData[currentLang].technicInfos.ihaleInfos.constructor}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{landData[currentLang].technicInfos.ihaleInfos.consultantTitle}</th>
                                    <td>{landData[currentLang].technicInfos.ihaleInfos.consultant}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </PageWrapper>
        </>
    )
}

export default TenderInfos