import React, { useState } from 'react'
import style from './reports.module.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Metadata } from '../../Helpers/Metadata';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const Reports = ({ route }) => {

  const currentLang = localStorage.getItem('lang');
  const [selectmonth, setSelectMonth] = useState('Ay Seçiniz');
  const [selectyear, setSelectYear] = useState('Yıl Seçiniz');


  const bigData = [
    {
      
    }
  ]

  return (
    <>
      <PageWrapper
        title={Metadata[currentLang].home.title}
        desc={Metadata[currentLang].home.desc}
        whiteHeader
        path={route}
      >
        <div className={style.reportsWrapper}>
          <Breadcrumbs path={route} />
          <div className={style.reportsInnerWrapper}>
            <div className={style.mapScope}>
              <div className={style.mapScopeInner}>

              </div>
            </div>
            <div className={style.contentScope}>
              <h3>Mutlukent</h3>
              <div className={style.innerContentScope}>
                <select name="monthPicker" id="monthPicker"
                  value={selectmonth}
                  onChange={e => setSelectMonth(e.target.value)}
                >
                  <option value="ocak">Ocak</option>
                  <option value="subat">Şubat</option>
                  <option value="mart">Mart</option>
                  <option value="nisan">Nisan</option>
                  <option value="mayis">Mayıs</option>
                  <option value="haziran">Haziran</option>
                  <option value="temmuz">Temmuz</option>
                  <option value="agustos">Ağustos</option>
                  <option value="eylul">Eylül</option>
                  <option value="ekim">Ekim</option>
                  <option value="kasim">Kasım</option>
                  <option value="aralik">Aralık</option>
                </select>
                <select name="yearPicker" id="yearPicker"
                  value={selectyear}
                  onChange={e => setSelectYear(e.target.value)}
                >
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </select>
                <button>Ara</button>
              </div>
              <div className={style.resultsContent}>
                <span>{selectmonth + "~" + selectyear}</span>

              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  )
}

export default Reports