import React, { useEffect, useState } from 'react'
import { ArrowDownIcon, ArrowRightIcon, FacebookIcon, LanguageIcon, MailIcon, TwitterIcon, CloseIcon, HamburgerMenu } from '../../assets/icons';
import Logo from '../../assets/img/navbar/logo.png'
import style from './navbar.module.scss'
import { landData } from '../../Helpers/Lang'
import { useLocation, useNavigate } from 'react-router-dom';
import { Fade } from 'react-reveal';
import miniLogo from '../../assets/img/navbar/ulastirmaLogo.png'

const LanguageCard = () => {

    const navigate = useNavigate();

    return (
        <div className="lCard">
            <label htmlFor="language" className={style.language}>
                <select
                    onChange={e => {
                        localStorage.setItem('lang', e.target.value);
                        navigate(0);
                    }}
                    id="language"
                    name="language"
                    value={localStorage.getItem('lang')}
                >
                    <option value={'tr'}>TÜRKÇE</option>
                    <option value={'en'}>ENGLISH</option>
                </select>
                <LanguageIcon language={localStorage.getItem('lang')} />
                <ArrowDownIcon />
            </label>
        </div>
    );

}

const MobileMenu = ({ whiteHeader, closeClick }) => {

    const currentLanguage = localStorage.getItem('lang');
    const navigate = useNavigate();
    const location = useLocation();
    const [isPopupOpen, setPopupOpen] = useState();

    const data = [
        {
            title: landData[currentLanguage].navbar.parts[0].title,
            link: '*'
        },
        {
            title: landData[currentLanguage].navbar.parts[1].title,
            innerTitle: [
                {
                    name: landData[currentLanguage].navbar.parts[1].innerTitle[0].name,
                    link: '/istasyonlar',
                    subTitle: [
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[0]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[1]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[2]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[3]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[4]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[5]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[6]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[7]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[8]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[9]
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[10]
                        }
                    ]
                },
                {
                    name: landData[currentLanguage].navbar.parts[1].innerTitle[1].name
                },
                {
                    name: landData[currentLanguage].navbar.parts[1].innerTitle[2].name
                },
                {
                    name: landData[currentLanguage].navbar.parts[1].innerTitle[3].name
                }
            ]
        },
        {
            title: landData[currentLanguage].navbar.parts[2].title,
            link: '/news'
        },
        {
            title: landData[currentLanguage].navbar.parts[3].title,
            innerTitle: [
                {
                    name: landData[currentLanguage].navbar.parts[3].innerTitle[0]
                },
                {
                    name: landData[currentLanguage].navbar.parts[3].innerTitle[1]
                }
            ]
        },
        {
            title: landData[currentLanguage].navbar.parts[4].title,
            link: '/reports',
        },
        {
            title: landData[currentLanguage].navbar.parts[5].title,
            link: '/contact',
        }
    ]

    return (
        <Fade distance='100px' left>
            <div className={style.customMobileMenu}>
                <button onClick={closeClick}>
                    <CloseIcon color={'white'} />
                </button>
                <div className={style.menuList}>
                    <ul className={style.primaryUl}>
                        {data.map((item, idx) => {
                            if (idx === 1) {
                                return (
                                    <li className={style.primaryLi}>
                                        {item.title}
                                        <ArrowDownIcon color={'#FFFFFF'} />
                                        <ul className={style.secondaryUl}>
                                            {
                                                item.innerTitle.map((i, indexx) => {
                                                    if (indexx === 0) {
                                                        return (
                                                            <li className={style.secondaryLi}>
                                                                {i.name}
                                                                <ArrowRightIcon color={'#FFFFFF'} />
                                                                <ul className={style.thirdUl}>
                                                                    {
                                                                        i.subTitle.map((lap) => {
                                                                            return (
                                                                                <li className={style.thirdLi}>
                                                                                    {lap.inner}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </li>
                                                        )
                                                    }
                                                    return (
                                                        <li>
                                                            {i.name}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>

                                );
                            }
                            else if (idx === 3) {
                                return (
                                    <li className={style.primaryLi}>
                                        {item.title}
                                        <ArrowDownIcon color={'#FFFFFF'} />
                                        <ul className={style.secondaryUl}>
                                            {
                                                item.innerTitle.map((j) => {
                                                    return (
                                                        <li className={style.primaryLi}>{j.name}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                )
                            }
                            return (
                                <li key={idx} className={style.primaryLi}>
                                    {item.title}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <LanguageCard />
            </div>
        </Fade>
    );
}


const Navbar = ({ whiteHeader }) => {

    const currentLanguage = localStorage.getItem('lang');
    const navigation = useNavigate();
    const [isOpen, setOpen] = useState(false);

    const data = [
        {
            title: landData[currentLanguage].navbar.parts[0].title,
            link: '/*'
        },
        {
            title: landData[currentLanguage].navbar.parts[1].title,
            innerTitle: [
                {
                    name: landData[currentLanguage].navbar.parts[1].innerTitle[0].name,
                    link: '/stations',
                    subTitle: [
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[0],
                            link: '/stations/1',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[1],
                            link: '/stations/2',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[2],
                            link: '/stations/3',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[3],
                            link: '/stations/4',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[4],
                            link: '/stations/5',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[5],
                            link: '/stations/6',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[6],
                            link: '/stations/7',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[7],
                            link: '/stations/8',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[8],
                            link: '/stations/9',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[9],
                            link: '/stations/10',
                        },
                        {
                            inner: landData[currentLanguage].navbar.parts[1].innerTitle[0].subTitle[10],
                            link: '/stations/11',
                        }
                    ]
                },
                {
                    name: landData[currentLanguage].navbar.parts[1].innerTitle[1].name,
                    link: '/warehouse'
                },
                {
                    name: landData[currentLanguage].navbar.parts[1].innerTitle[2].name,
                    link: '/gdm'
                },
                {
                    name: landData[currentLanguage].navbar.parts[1].innerTitle[3].name,
                    link: '/tenderinfos'
                }
            ]
        },
        {
            title: landData[currentLanguage].navbar.parts[2].title,
            link: '/news'
        },
        {
            title: landData[currentLanguage].navbar.parts[3].title,
            innerTitle: [
                {
                    name: landData[currentLanguage].navbar.parts[3].innerTitle[0],
                    link:'/assetmedia'
                },
                {
                    name: landData[currentLanguage].navbar.parts[3].innerTitle[1]
                }
            ]
        },
        {
            title: landData[currentLanguage].navbar.parts[4].title,
            link: '/reports',
        },
        {
            title: landData[currentLanguage].navbar.parts[5].title,
            link: '/contact',
        },
        {
            title: miniLogo
        }
    ]

    const [scrollY, setScrollY] = useState(0);
    const [className, setClassName] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const newScrollY = window.scrollY;
            setScrollY(newScrollY);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setClassName(scrollY > 40 ? true : false);
    }, [scrollY]);

    return (
        <div className={className ? style.customnavbarNew : style.customnavbar}>
            {isOpen && (
                <MobileMenu
                    closeClick={() => {
                        setOpen(false);
                    }}
                    whiteHeader={whiteHeader}
                    setOpen={setOpen}
                />
            )}
            <div className={style.mobileMenu}>
                <button onClick={() => setOpen(!isOpen)}>
                    <HamburgerMenu color={'white'} />
                </button>
            </div>
            <div className={style.logo}>
                <img src={Logo} alt="main_logo" onClick={() => navigation('/*')} />
            </div>
            <div className={style.container}>
                <div className={className ? style.containerTopNew : style.containerTop}>
                    <a href="">GEBZEDARICAMETRO.COM</a>
                    <a href="#" target={'_blank'}>
                        <FacebookIcon color={'#ADADAD'} />
                    </a>
                    <a href="#" target={'_blank'}>
                        <TwitterIcon color={'#ADADAD'} />
                    </a>
                    <a href="#" target={'_blank'}>
                        <MailIcon color={'#ADADAD'} />
                    </a>
                    <LanguageCard />
                </div>
                <div className={className ? style.containerBottomNew : style.containerBottom}>
                    {
                        data.map((item, index) => {
                            if (index === 1) {
                                return (
                                    <a href={item.link} className={style.arrow}>
                                        {item.title}
                                        <ArrowDownIcon color={'#FFFFFF'} />
                                        <div className={style.dropdown}>
                                            {
                                                item.innerTitle.map((i, indexx) => {
                                                    if (indexx === 0) {
                                                        return (
                                                            <a href={i.link} className={style.arrow}>
                                                                {i.name}
                                                                <ArrowRightIcon color={'#FFFFFF'} />
                                                                <div className={style.dropdownSecond}>
                                                                    {
                                                                        i.subTitle.map((lap) => {
                                                                            return (
                                                                                <a href={lap.link}>
                                                                                    {lap.inner}
                                                                                </a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </a>
                                                        )
                                                    }
                                                    return (
                                                        <a href={i.link}>
                                                            {i.name}
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </a>
                                )
                            }
                            else if (index === 3) {
                                return (
                                    <a href={item.link} className={style.arrow}>
                                        {item.title}
                                        <ArrowDownIcon color={'#FFFFFF'} />
                                        <div className={style.dropdownThird}>
                                            {
                                                item.innerTitle.map((j) => {
                                                    return (
                                                        <a href={j.link}>{j.name}</a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </a>
                                )
                            }
                            return (
                                <>
                                    {
                                        index === 6
                                            ?
                                            <img src={item.title} alt="" />
                                            :
                                            <a href={item.link}>
                                                {item.title}
                                            </a>
                                    }
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Navbar;